import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { User } from '../_models'
import { Subject } from 'rxjs';

@Injectable() 
export class AuthenticationService {
    private headers: HttpHeaders;
    private currentUserSubject: BehaviorSubject<User>;
    public currentUser: Observable<User>;
    
    private openFbPopup = new Subject<any>();
    openFbPopup$ = this.openFbPopup.asObservable();

    constructor(
        public http: HttpClient
    ) {
        this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
        this.currentUser = this.currentUserSubject.asObservable();

        
        
    }

    updateOpenFbPopup(item) {
        this.openFbPopup.next(item);
    }

    public get currentUserValue(): User {
        return this.currentUserSubject.value;
    }

    login(email: string, password: string) {
        const formData = new FormData();

        formData.append('email', email);
        formData.append('password', password);
        formData.append('web_environment', '1');

        return this.http.post<any>(`${environment.apiUrl}/login`, formData)
        .pipe(map(user => {
            if(user && user.msg_code == 301) {
                localStorage.setItem('currentUser', JSON.stringify(user.data));
                localStorage.setItem('resetPassword', '');
                this.currentUserSubject.next(user);
            }
            return user;
        }));
    }

    register(user: User) {
        const formData = new FormData();

        formData.append('email', user.email);
        formData.append('u_first_name', user.first_name);
        formData.append('u_last_name', user.last_name);
        formData.append('phone_no', user.phone);
        formData.append('phone_country_code', '+1');
        formData.append('password', user.password);
        formData.append('confirm_password', user.confirm_password);
        formData.append('web_environment', '1');

        return this.http.post<any>(`${environment.apiUrl}/signup`, formData)
        .pipe(map(data => {
            if(data) {
                if(data.msg_code != 201) {
                    localStorage.setItem('currentUser', JSON.stringify(data.data));
                    localStorage.setItem('resetPassword', '');
                    this.currentUserSubject.next(user);
                }
            }
            return data;
        }));
    }

    forgotPassword(email: string) {
        const formData = new FormData();

        formData.append('email', email);
        
        return this.http.post<any>(`${environment.apiUrl}/forgot-password`, formData)
        .pipe(map(forgot => {
            
            if(forgot && forgot.msg_code == environment.resetToken) {
                localStorage.setItem('resetPassword', forgot.data.reset_token);
            }
            return forgot;
        }));
    }

    checkResetToken(token: string) {
        const formData = new FormData();

        formData.append('reset_token', token);

        return this.http.post<any>(`${environment.apiUrl}/check-reset-token`, formData)
        .pipe(map(data => {
            return data;
        }))
    }

    resetPassword(otp: string, password: string, confirm_password: string, reset_token: string) {
        const formData = new FormData();

        formData.append('otp', otp);
        formData.append('newpassword', password);
        formData.append('confirmpassword', confirm_password);
        formData.append('reset_token', reset_token);

        return this.http.post<any>(`${environment.apiUrl}/reset-password`, formData)
        .pipe(map(data => {
            if(data && data.msg_code == 608) {
                localStorage.setItem('resetPassword', '');
            }
            return data;
        }));
    }

    logout() {
        localStorage.removeItem('currentUser');
        localStorage.removeItem('userAddress');
        localStorage.removeItem('order');
        localStorage.removeItem('order_id');
        this.currentUserSubject.next(null);
    }
}