import { Component, OnInit, Renderer2, ViewChild, ElementRef } from '@angular/core';
import { DialogComponent, DialogService } from "ng6-bootstrap-modal";
import { AuthService, FacebookLoginProvider } from 'angular-6-social-login';
import { HttpClient, HttpParams } from '@angular/common/http';
import { CropperComponent, ImageCropperResult } from 'angular-cropperjs';

import { UserService, AuthenticationService } from '../../_services';
import { first } from 'rxjs/operators';
import { ConfirmUploadComponent } from '../confirm_upload/confirm_upload.component';
import { FacebookService, InitParams, LoginResponse } from 'ngx-facebook';
import { environment } from 'src/environments/environment';

export interface ConfirmModel {
  title: string;
  message: string;
  activeTab:string;
}

@Component({
  selector: 'confirm',
  styleUrls: ['./upload.component.scss'],
  templateUrl: 'upload.component.html'
})
export class UploadComponent extends DialogComponent<ConfirmModel, boolean> implements ConfirmModel, OnInit {
  title: string;
  message: string;
  activeTab: string;
  instaLogin: boolean = false;
  facebookLogin: boolean = false;
  mainLoader: boolean = false;

  instaImages: any = [];
  facebookImages: any = [];

  imageUrl = '';
  cropImage = '';
  classTab:any;

  active = 'nav-item active';
  inactive = 'nav-item';


  @ViewChild('cropperImg', { static: false }) public cropperImg: ElementRef;
  constructor(
    dialogService: DialogService,
    private userService: UserService,
    private http: HttpClient,
    private socialAuthService: AuthService,
    private renderer: Renderer2,
    private dialog: DialogService,
    private authenticationService: AuthenticationService,
    private fb: FacebookService
  ) {
    super(dialogService);

    let initParams: InitParams = {
      appId: environment.facebook_client_key,
      xfbml: true,
      version: 'v2.8'
    };

    fb.init(initParams);
    
  }

  loginWithFacebook(): void {

    this.fb.login()
      .then((response: LoginResponse) => console.log(response))
      .catch((error: any) => console.error(error));

  }

  onNavigate() {
    this.authenticationService.updateOpenFbPopup(true);


    let redirect_uri = 'https://snaptiles.com/facebook';
    let url = `https://www.facebook.com/v5.0/dialog/oauth?client_id=${environment.facebook_client_key}&redirect_uri=${redirect_uri}&response_type=token&scope=user_photos`
    window.open(url, "blank");
   

  }

  ngOnInit() {
    this.fetchInstaPhotos();
    let facebook = localStorage.getItem('facebook_access_token');
    let facebook_user_id = localStorage.getItem('facebook_user_id');
    if (facebook != '' && facebook != null) {
      this.facebookLogin = true;
      this.getPhotos(facebook, 'me');
    } else {
      this.facebookLogin = false;
    }
  }


  async socialSignIn(socialPlatform: string) {
    let socialPlatformProvider;
    if (socialPlatform == 'facebook') {
      socialPlatformProvider = FacebookLoginProvider.PROVIDER_ID

      this.socialAuthService.signIn(socialPlatformProvider).then(
        userData => {
          this.facebookLogin = true;
          localStorage.setItem('facebook_access_token', userData.token);
          localStorage.setItem('facebook_user_id', userData.id);
          this.getPhotos(userData.token, userData.id);
        }
      );
    } else {
      let that = this;
      await this.userService.instagramLogin();
      this.authenticationService.updateOpenFbPopup(true);
      this.mainLoader = true;
      // setTimeout(() => {
        that.fetchInstaPhotos();
        that.mainLoader = false;
      // }, 5000)
    }
  }

  fetchInstaPhotos() {
    let instagram = localStorage.getItem('instagram_access_token');
    const tiles = JSON.parse(localStorage.getItem('tiles'));

    if (instagram != '' && instagram != null) {

      this.instaLogin = true;
      const params = new HttpParams().set('access_token', instagram);

      this.http.get<any>("https://api.instagram.com/v1/users/self/media/recent/", { params: params })
        .subscribe(login => {
          let data = login.data;
          let images: any = [];
          let i = 0;
          data.forEach(data => {
            if (typeof tiles !== 'undefined' && tiles !== null) {
              let matched = tiles.find(ob => ob['id'] === data.id);
              if (typeof matched !== 'undefined') {
                this.instaImages.push({ id: data.id, image: data.images.standard_resolution.url, thumbnail: data.images.thumbnail.url, selected: true });
              } else {
                this.instaImages.push({ id: data.id, image: data.images.standard_resolution.url, thumbnail: data.images.thumbnail.url, selected: false });
              }
            } else {
              this.instaImages.push({ id: data.id, image: data.images.standard_resolution.url, thumbnail: data.images.thumbnail.url, selected: false });
            }
          });

          this.moreInstaImages(login.pagination.next_url);

        });
    } else {
      this.instaLogin = false;
    }
  }

  moreInstaImages(next_url) {
    const tiles = JSON.parse(localStorage.getItem('tiles'));
    let paging = next_url;
    if (typeof paging !== 'undefined') {
      this.http.get<any>(paging)
        .subscribe(login => {
          let data = login.data;
          data.forEach(data => {
            if (typeof tiles !== 'undefined' && tiles !== null) {
              let matched = tiles.find(ob => ob['id'] === data.id);
              if (typeof matched !== 'undefined') {
                this.instaImages.push({ id: data.id, image: data.images.standard_resolution.url, thumbnail: data.images.thumbnail.url, selected: true });
              } else {
                this.instaImages.push({ id: data.id, image: data.images.standard_resolution.url, thumbnail: data.images.thumbnail.url, selected: false });
              }
            } else {
              this.instaImages.push({ id: data.id, image: data.images.standard_resolution.url, thumbnail: data.images.thumbnail.url, selected: false });
            }
          });

          if (typeof login.pagination.next_url !== 'undefined') {
            this.moreInstaImages(login.pagination.next_url);
          }
        });
    }
  }

  getPhotos(token: string, user_id) {
    console.log(token, '-token -- user_id-', user_id);

    const params = new HttpParams().set('access_token', token);

    const photoParams = new HttpParams()
      .set('access_token', token)
      .set('fields', 'source');

    const tiles = JSON.parse(localStorage.getItem('tiles'));

    //     curl -i -X GET \
    //  "https://graph.facebook.com/v5.0/{album-id}/photos?access_token="

    this.http.get<any>('https://graph.facebook.com/' + user_id + '/albums', { params: params })
      .subscribe(data => {
        let albums = data.data;
        let images: any = [];
        let i = 0;
        albums.forEach(albumData => {
          this.http.get<any>('https://graph.facebook.com/' + albumData.id + '/photos', { params: photoParams })
            .subscribe(photos => {
              if (photos.data.length > 0) {
                let imagesList = photos.data;
                imagesList.forEach(img => {
                  if (typeof tiles !== 'undefined' && tiles !== null) {
                    let matched = tiles.find(ob => ob['id'] === img.id);
                    if (typeof matched !== 'undefined') {
                      images.push({ id: img.id, source: img.source, selected: true });
                    } else {
                      images.push({ id: img.id, source: img.source, selected: false });
                    }
                  } else {
                    images.push({ id: img.id, source: img.source, selected: false });
                  }
                });
                if (typeof photos.paging.next !== 'undefined') {
                  this.nextFacebook(photos.paging.next);
                }
              }
            });
        }, error => {
          console.log(error);
        });
        this.facebookImages = images;
      }, error => {
        // this.socialAuthService.signOut();
        localStorage.removeItem('facebook_access_token_set');
        localStorage.removeItem('facebook_access_token');
        this.facebookLogin = false;
        return;
      });
  }

  nextFacebook(next) {
    const tiles = JSON.parse(localStorage.getItem('tiles'));

    this.http.get<any>(next)
      .subscribe(photosNext => {
        if (photosNext.data.length > 0) {
          let imagesList = photosNext.data;
          imagesList.forEach(img => {
            if (typeof tiles !== 'undefined' && tiles !== null) {
              let matched = tiles.find(ob => ob['id'] === img.id);
              if (typeof matched !== 'undefined') {
                this.facebookImages.push({ id: img.id, source: img.source, selected: true });
              } else {
                this.facebookImages.push({ id: img.id, source: img.source, selected: false });
              }
            } else {
              this.facebookImages.push({ id: img.id, source: img.source, selected: false });
            }
          });
          if (typeof photosNext.paging.next !== 'undefined') {
            this.nextFacebook(photosNext.paging.next);
          }
        }
      });
  }

  logoutInsta() {
    localStorage.removeItem('instagram_access_token');
    localStorage.removeItem('instagram_access_token_set');
    this.instaLogin = false;
    return;
  }

  logoutFacebook() {
    localStorage.removeItem('facebook_access_token');
    localStorage.removeItem('facebook_access_token_set');
    this.facebookLogin = false;
    return;
  }

  closeUpload() {
    this.renderer.removeClass(document.body, 'modal-open');
    this.close();
  }

  keepImage(image) {
    const tilesData = JSON.parse(localStorage.getItem('tiles'));

    let image_url = '';

    if (typeof image.source !== 'undefined') {
      image_url = image.source;
    } else {
      image_url = image.image;
    }

    if (typeof tilesData !== 'undefined' && tilesData !== null && tilesData.length > 0) {

      let matched = tilesData.find(ob => ob['id'] === image.id);

      if (typeof matched !== 'undefined') {
        const updateTilesData = tilesData.filter(tile => tile.id !== image.id);
        localStorage.setItem('tiles', JSON.stringify(updateTilesData));
        this.result = true;
        this.close();
      } else {
        var that = this;
        var image_url_facebook = image_url;
        this.imageUrl = image_url;
        setTimeout(() => {
          var cropper = new Cropper(that.cropperImg.nativeElement, {
            aspectRatio: 1,
            autoCropArea: 1,
            crop: function (event) {
              that.cropImage = cropper.getCroppedCanvas().toDataURL('image/jpeg');
              that.userService.uploadImageBase64(that.cropImage)
                .pipe(first())
                .subscribe(data => {
                  if (data && data.msg_code == 200) {
                    tilesData.push({ id: image.id, image: image_url_facebook, cropUrl: data.data.url, qty: 1 });
                    localStorage.setItem('tiles', JSON.stringify(tilesData));
                    this.mainLoader = false;
                    this.result = true;
                    that.close();
                  } else {
                    this.mainLoader = false;
                  }
                });
            }
          });
        }, 3000);
      }
    } else {
      var that = this;
      var image_url_facebook = image_url;
      this.imageUrl = image_url;
      setTimeout(() => {
        var cropper = new Cropper(that.cropperImg.nativeElement, {
          aspectRatio: 1,
          autoCropArea: 1,
          crop: function (event) {
            that.cropImage = cropper.getCroppedCanvas().toDataURL('image/jpeg');
            that.userService.uploadImageBase64(that.cropImage)
              .pipe(first())
              .subscribe(data => {
                if (data && data.msg_code == 200) {
                  let tiles: any = [
                    {
                      id: image.id,
                      image: image_url_facebook,
                      cropUrl: data.data.url,
                      qty: 1
                    }
                  ];
                  localStorage.setItem('tiles', JSON.stringify(tiles));
                  this.result = true;
                  that.close();
                  this.mainLoader = false;
                } else {
                  this.mainLoader = false;
                }
              });
          }
        });
      }, 3000);
    }
  }

  selectImage(image) {
    const tilesData = JSON.parse(localStorage.getItem('tiles'));
    if (image.selected) {
      for (let i = 0; i < tilesData.length; i++) {
        console.log('Data Remove');
        if (tilesData[i].id === image.id) {
          tilesData.splice(i, 1);
          localStorage.setItem('tiles', JSON.stringify(tilesData));
          this.result = true;
          this.close();
        }
      }
    } else {
      let that = this;
      this.mainLoader = true;
      var img = new Image();
      img.src = typeof image.source !== 'undefined' ? image.source : image.image;
      img.onload = function () {
        if (img.width < 1000 || img.height < 1000) {
          that.mainLoader = false;
          let disposable = that.dialog.addDialog(ConfirmUploadComponent, {
            title: '',
            message: 'This photo is actually pretty small. It will probably make a blurry tile!'
          })
            .subscribe((isConfirmed) => {
              if (isConfirmed) {
                that.mainLoader = true;
                that.keepImage(image);
              }
            });
        } else {
          that.keepImage(image);
        }
      }
    }
  }
}