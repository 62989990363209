import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthenticationService, AlertService, HeaderService } from '../_services';
import { first } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Component({
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  title = '';
  forgotForm: FormGroup;
  submitted: boolean = false;
  loading: boolean = false;
  returnUrl: string;
  error: string = '';

  constructor (
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private alertService: AlertService,
    private headerService: HeaderService
  ) {
    if(this.authenticationService.currentUserValue) {
      this.router.navigate(['/']);
    }
  }

  ngOnInit() {
    this.headerService.setTitle(this.title);
    this.forgotForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
    });

    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }

  get f() {
    return this.forgotForm.controls;
  }

  onSubmit() {
    this.submitted = true;

    if(this.forgotForm.invalid) {
      return;
    }

    this.loading = true;
    this.authenticationService.forgotPassword(this.f.email.value)
    .pipe(first())
    .subscribe(data => {
      if(data.msg_code == environment.forgotPasswordEmailCheck) {
        this.loading = false;
        this.alertService.error(data.message);
      } else if(data.msg_code == environment.forgotPasswordSuccess) {
        this.alertService.success(data.message);
        setTimeout(() => {
          this.router.navigate(['/reset-password']);
        }, 3000);
        
      }
    }, error => {
      this.loading = false;
      this.alertService.error(error);
    });
  }
}
