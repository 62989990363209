import { Component, OnInit, Renderer2, ViewChild, ElementRef } from '@angular/core';
import { DialogComponent, DialogService } from "ng6-bootstrap-modal";

export interface ConfirmationModel {
    title:string;
    message:string;
    confirmtext:string;
    canceltext:string;
}

@Component({  
    selector: 'confirm-upload',
    styleUrls: ['./confirm_upload.component.scss'],
    templateUrl: 'confirm_upload.component.html'
})
export class ConfirmUploadComponent extends DialogComponent<ConfirmationModel, boolean> implements ConfirmationModel, OnInit {
    title: string = 'Low Quality Image';
    message: string = 'This photo is actually pretty small. It will likely produce a blurry tile!';
    confirmtext:string = 'Keep Anyway';
    canceltext:string = 'Remove';
    mainLoader = false;
    
    constructor(
        dialogService: DialogService,
        private renderer: Renderer2,
    ) {
        super(dialogService);
    }

    ngOnInit() {
    }

    keep() {
        this.renderer.removeClass(document.body, 'modal-open');
        this.result = true;
        this.close();
    }

    closeConfirmUpload() {
        this.renderer.removeClass(document.body, 'modal-open');
        this.result = false;
        this.close();
    }
}