import { Component, OnInit } from '@angular/core';
import { HeaderService, UserService, AuthenticationService, AlertService } from '../_services';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';

@Component({
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  title = 'Snap Tiles - Profile';
  profileForm: FormGroup;
  profilePhone: FormGroup;
  submitted: boolean = false;
  loading: boolean = false;
  error: string = '';
  addressData: boolean = false;
  address: string = '';
  editPhone: boolean = false;
  mainLoader: boolean = false;
  loadingPhone: boolean = false;
  errorMessage: string = '';

  user: any = {
    first_name: '',
    last_name: '',
    email: '',
    phone_no: '',
  };

  constructor (
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private authenticationService: AuthenticationService,
    private headerService: HeaderService,
    private alertService: AlertService
  ) {

  }

  ngOnInit() {
      localStorage.removeItem('back');
      this.mainLoader = true;
      this.headerService.setTitle('Profile');
      this.headerService.setMenu('false', 'ProfilePage');
      this.profileForm = this.formBuilder.group({
        first_name: ['', Validators.required],
        last_name: ['', Validators.required]
      });

      this.profilePhone = this.formBuilder.group({
        phone_no: ['']
      });
      
      const user = JSON.parse(localStorage.getItem('currentUser'));
      if(user != null) {
        this.user.first_name = user.u_first_name;
        this.user.last_name = user.u_last_name;
        this.user.access_token = user.access_token;
        this.user.phone_no = user.phone_no;
        this.user.email = user.email;

        this.userService.getAddress(this.user.access_token)
        .pipe(first())
        .subscribe(data => {
          this.mainLoader = false;
          if(data && data.msg_code == 811) {
            this.addressData = false;
          } else if(data && data.msg_code == 603) {
            this.mainLoader = false;
            this.addressData = false;
            this.authenticationService.logout();
            this.router.navigate(['/login']);
          } else {
            const address = data.data;
            
            this.addressData = true;
            this.address = address.address_1+', '+address.city+', '+address.state+', '+address.postcode;
            if(address.country_id == 223) {
              this.address += ', USA';
            }
          }
        });
      } else {

      }
  }

  number(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if(charCode > 33 && (charCode < 48 || charCode > 57 )) {
      return false;
    }
    return true;
  }

  get f() {
    return this.profileForm.controls;
  }

  get p() {
    return this.profilePhone.controls;
  }

  onSubmit() {
    this.submitted = true;

    if(this.profileForm.invalid) {
      return;
    }
    const user = JSON.parse(localStorage.getItem('currentUser'));

    if(user != null) {
      if(user.u_first_name == this.user.first_name && user.u_last_name == this.user.last_name) {
        this.submitted = false;
        this.loading = false;
        this.alertService.info('Nothing to be update.');
        return;  
      }
    }
    this.loading = true;
    this.userService.updateUserProfile(this.user)
    .pipe(first())
    .subscribe(data => {
      if(data && data.msg_code == 616) {
        const user = JSON.parse(localStorage.getItem('currentUser'));
        
        user.u_first_name = this.user.first_name;
        user.u_last_name = this.user.last_name;
        user.fullname = user.u_first_name + ' ' + user.u_last_name;

        localStorage.setItem('currentUser', JSON.stringify(user));
        this.alertService.success(data.message);
      }
      this.loading = false;
    }, error => {
      this.loading = false;
      this.alertService.error(error);
    });
  }

  openAddress() {
    localStorage.setItem('back', 'profile');
    this.router.navigate(['/address']);
  }

  phoneEdit() {
    this.editPhone = true;
    this.errorMessage = '';
  }

  cancelPhoneUpdate() {
    const user = JSON.parse(localStorage.getItem('currentUser'));
    this.user.phone_no = user.phone_no;
    this.editPhone = false;
    this.errorMessage = '';
  }
  
  updatePhone() {
    const user = JSON.parse(localStorage.getItem('currentUser'));

    if(this.p.phone_no.value == user.phone_no) {
      this.editPhone = false;
      return;
    }

    if(this.p.phone_no.value.length < 6) {
      this.errorMessage = "Phone no should be min 6 digit.";
      return;
    }

    const userPhone = {access_token: this.user.access_token, phone_no: this.p.phone_no.value};
    this.loadingPhone = true;
    this.userService.updatePhoneNumber(userPhone)
    .pipe(first())
    .subscribe(data => {
      if(data && data.msg_code == 818) {
        const user = JSON.parse(localStorage.getItem('currentUser'));

        this.user.phone_no = user.phone_no = this.p.phone_no.value;
         
        localStorage.setItem('currentUser', JSON.stringify(user));
        this.errorMessage = '';
        this.editPhone = false;
      } else if(data.msg_code == 819) {
        this.errorMessage = data.message;
        // this.editPhone = false;
      } else {
        this.editPhone = false;
      }
      this.loadingPhone = false;
    }, err => {
      this.errorMessage = err;
      this.loadingPhone = false;
    });
  }
}
