import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpRequest, HttpResponse, HttpEventType, HttpEvent } from '@angular/common/http';

import { environment } from '../../environments/environment';
import { User, Verification } from '../_models';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable() 
export class UserService {
  
    imageUploaded:boolean;
    constructor(
        private http: HttpClient
    )
    {

    }

    updateUserProfile(user: any) {
        const formData = new FormData();

        formData.append('u_first_name', user.first_name);
        formData.append('u_last_name', user.last_name);
        formData.append('apitoken', user.access_token);

        return this.http.post<any>(`${environment.apiUrl}/profile`, formData)
        .pipe(map(data => {
            console.log(data);
            return data;
        }));
    }

    updatePhoneNumber(user: any) {
        const formData = new FormData();

        formData.append('apitoken', user.access_token);
        formData.append('phone_no', user.phone_no);
        formData.append('phone_country_code', '+1');

        return this.http.post<any>(`${environment.apiUrl}/edit-phone`, formData)
        .pipe(map(data => {
            console.log(data);
            return data;
        }));
    }

    changePassword(password: any) {
        const formData = new FormData();

        formData.append('apitoken', password.access_token);
        formData.append('currentpassword', password.currentpassword);
        formData.append('newpassword', password.newpassword);
        formData.append('confirmpassword', password.confirmpassword);

        return this.http.post<any>(`${environment.apiUrl}/change-password`, formData)
        .pipe(map(data => {
            return data;
        }));
    }

    getAddress(token: string) {
        const params = new HttpParams().set('apitoken', token);

        return this.http.get<any>(`${environment.apiUrl}/address`, {params: params})
        .pipe(map(data => {
            if(data && data.msg_code == 200) {
                const address = JSON.stringify(data.data);
                localStorage.setItem('userAddress', address);
            }
            return data;
        }));
    }

    updateAddress(address: any) {
        const formData = new FormData();

        formData.append('apitoken', address.access_token);
        formData.append('address_1', address.address_1);
        formData.append('address_2', address.address_2);
        formData.append('city', address.city);
        formData.append('state', address.state);
        formData.append('postcode', address.postcode);
        formData.append('country_id', address.country_id);

        return this.http.post<any>(`${environment.apiUrl}/address`, formData)
        .pipe(map(data => {
            return data;
        }));
    }

    couponStatus(order_id: string) {
        const formData = new FormData();
        formData.append('order_id', order_id);
        
        return this.http.post<any>(`${environment.apiUrl}/check-coupon-status`, formData)
        .pipe(map(data => {
            console.log('Coupon Status: ', data);
            return data;
        }));
    }

    removeCoupon(order_id: string) {
        const formData = new FormData();
        formData.append('order_id', order_id);
        
        return this.http.post<any>(`${environment.apiUrl}/coupon-remove`, formData)
        .pipe(map(data => {
            console.log('Coupon Removed: ', data);
            return data;
        }));
    }



    checkCouponStatus(order_id: string) {
        const formData = new FormData();
        formData.append('order_id', order_id);
        return this.http.post<any>(`${environment.apiUrl}/check-coupon-status`, formData)
        .pipe(map(data => {
            console.log('checkCouponStatus: ', data);
            return data;
        }));
    }
    
    applyCoupon(order_id: string, coupon_code:any) {
        const formData = new FormData();
        formData.append('order_id', order_id);
        formData.append('coupon_code', coupon_code);
        
        return this.http.post<any>(`${environment.apiUrl}/coupon-apply`, formData)
        .pipe(map(data => {
            console.log('applyCoupon: ', data);
            return data;
        }));
    }

    makeFreePayment(order_id: string) {
        const formData = new FormData();
        formData.append('order_id', order_id);

        return this.http.post<any>(`${environment.apiUrl}/free-checkout`, formData)
        .pipe(map(data => {
            console.log('makeFreePayment: ', data);
            return data;
        }));
    }

    orderList(token: string) {
        const params = new HttpParams()
                            .set('apitoken', token)
                            .set('page', '1');

        return this.http.get<any>(`${environment.apiUrl}/order-list`, {params: params})
        .pipe(map(data => {
            return data;
        }));
    }

    orderDetail(orderDetail) {
        const params = new HttpParams()
                            .set('apitoken', orderDetail.access_token)
                            .set('order_id', orderDetail.order_id);

        return this.http.get<any>(`${environment.apiUrl}/order-detail`, {params: params})
        .pipe(map(data => {
            return data;
        }));
    }

    orderStatus(orderDetailData:string) {
        const params = new HttpParams()
                            .set('order_id', orderDetailData);

        return this.http.get<any>(`${environment.apiUrl}/order-status`, {params: params})
        .pipe(map(data => {
            return data;
        }));
    }

    resendVerificationCode(verification: Verification) {
        const formData = new FormData();

        formData.append('email', verification.email);
        formData.append('type', verification.type);

        return this.http.post<any>(`${environment.apiUrl}/resend-verify-code`, formData)
        .pipe(map(data => {
            console.log(data);
        }));
    }

    getPhotos(token: string, user_id) {
        const params = new HttpParams().set('access_token', token);

        const photoParams = new HttpParams()
                            .set('access_token', token)
                            .set('fields', 'source, after');

        return this.http.get<any>('https://graph.facebook.com/'+user_id+'/albums', {params: params})
        .subscribe(data => {
            let albums = data.data;
            albums.forEach(albumData => {
                return this.http.get<any>('https://graph.facebook.com/'+albumData.id+'/photos', {params: photoParams})
                .subscribe(photos => {
                    console.log(photos);    
                });
            });
        });
    }

    instagramLogin() {
        let access_token = localStorage.getItem('instagram_access_token');

        if(access_token != '' && access_token != null) {
            const params = new HttpParams().set('access_token', access_token);
            
            return this.http.get<any>("https://api.instagram.com/v1/users/self/media/recent/", {params: params})
            .pipe(map(login => {
                let data = login.data;
                let images: any = [];
                data.forEach(data => {
                    images.push({id: data.id, image: data.images.standard_resolution.url});
                    console.log(data);
                });
                console.log(images);

                return images;
            }));
        } else {
            var url = `https://api.instagram.com/oauth/authorize/?client_id=${environment.instagram_client_id}&redirect_uri=${environment.instagram_redirect_url}?&response_type=token`; 
            window.open(
                url,
                '_blank'
            );
        }

        /* const params = new HttpParams()
                        .set('client_id', '3371ced9049e4d51b9006379b0df6c28')
                        .set('redirect_uri', 'http://localhost:4200/auth/instagram/callback')
                        .set('response_type', 'token');

        const request = new HttpRequest("GET", "https://api.instagram.com/oauth/authorize/?client_id=3371ced9049e4d51b9006379b0df6c28&redirect_uri=http://localhost:4200/instagram?&response_type=token");
        window.location.href = "https://api.instagram.com/oauth/authorize/?client_id=3371ced9049e4d51b9006379b0df6c28&redirect_uri=http://localhost:4200/instagram?&response_type=token";
        
                        this.http.get<any>('https://api.instagram.com/oauth/authorize/', {params: params})
                        .pipe(first())
                        .subscribe(login => {
                            console.log(login);
                        });
        `https://api.instagram.com/oauth/authorize/` */
    }

    uploadImage(files: File): Observable<any> {

        const formData = new FormData();
        
        formData.append('image',files);

        this.imageUploaded = false;
        return this.http.post<any>(`${environment.apiUrl}/temp-upload`, formData, {reportProgress: true, observe: "events"});
        /* .subscribe(event => {
            if (event.type === HttpEventType.UploadProgress) {
                // This is an upload progress event. Compute and show the % done:
                const percentDone = Math.round(100 * event.loaded / event.total);
                console.log(`File is ${percentDone}% uploaded.`);
                return percentDone;
                
              } else if (event instanceof HttpResponse) {
                  console.log(event.body);
                  this.imageUploaded = true;
                  return event.body;
              }
        }); */
        /* .pipe(
            map((event: HttpEvent<any>) => {
                console.log(event);
                if (event.type === HttpEventType.UploadProgress) {
                    // This is an upload progress event. Compute and show the % done:
                    const percentDone = Math.round(100 * event.loaded / event.total);
                    console.log(`File is ${percentDone}% uploaded.`);
                } else if (event instanceof HttpResponse) {
                    console.log(event.body);
                    this.imageUploaded = true;
                    return event.body;
                } 
            }
        )) */
    }

    uploadImageBase64(base64: any) {
        const formData = new FormData();
        formData.append('image', base64);
        /* console.log(base64); */
        return this.http.post<any>(`${environment.apiUrl}/temp-upload`, formData)
        .pipe(map(data => {
            /* console.log('Upload Image', data); */
            return data;
        }))
    }

    placeorder(order: any, token: string, order_id: string, user_detail:any) {
        const formData = new FormData();
        formData.append('detail', JSON.stringify(order));
        formData.append('user_detail', JSON.stringify(user_detail));
        formData.append('apitoken', token);
        if(order_id != '') {
            formData.append('order_id', order_id);
        }

        return this.http.post<any>(`${environment.apiUrl}/place-order`, formData)
        .pipe(map(data => {
            console.log('Place Order: ', data);
            return data;
        }));
    }

    paymentProcess(transction: any, order: string, token: string) {
        const formData = new FormData();

        formData.append('apitoken', token);
        formData.append('order_id', order);
        formData.append('payment_response', JSON.stringify(transction));

        return this.http.post<any>(`${environment.apiUrl}/payment-process`, formData)
        .pipe(map(data => {
            return data;
        }));
    }

    price() {
        return this.http.get<any>(`${environment.apiUrl}/price`)
        .pipe(map(data => {
            return data;
        }));
    }

    croppingImage(url, matrix) {
        const formData = new FormData();

        formData.append('url', url);
        formData.append('data', matrix);

        return this.http.post<any>(`${environment.apiUrl}/cropping-image`, formData)
        .pipe(map(data => {
            return data;
        }));
    }
}

