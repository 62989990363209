import { Component, Renderer2, ElementRef } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Location } from '@angular/common';
import { Title, Meta } from '@angular/platform-browser';
import { HeaderService, AuthenticationService, UserService } from './_services'
import { first } from 'rxjs/operators';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  host: {
    '(document:click)': 'onClick($event)',
  },
})

export class AppComponent {
  title: string = 'Snap Tiles';
  stack: any = [];
  menuClass: boolean = false;
  overlayStyle: boolean = false;
  menuData: boolean = false;
  userData: any = {
    first_name: ''
  };
  year: any;
  pricingData: any = {banner_text: ''};

  constructor(
    private router: Router,
    private location: Location,
    private headerService: HeaderService,
    private authenticationService: AuthenticationService,
    private renderer: Renderer2,
    private deviceService: DeviceDetectorService,
    private _eref: ElementRef,
    private titleService: Title,
    private meta: Meta,
    private userService: UserService
  ) {
    router.events.subscribe((val) => {
      if(val instanceof NavigationEnd) {
        this.renderer.removeClass(document.getElementById('menuTiles'), 'in');
      }

      this.titleService.setTitle('Magnetic Photo Tiles that snap together in seconds | Photo Wall Art | Stickable Photo Tiles');
      this.meta.updateTag({name: 'description', content: 'These are NOT your ordinary Photo Tiles! Snaptiles are the first magnetic photo tiles that hang in seconds. Hang one and Snap on the others!'});

    });

  }

  ngOnInit() {
    this.getPricingData();
    this.pricingData = JSON.parse(localStorage.getItem('pricingData'));
    if(this.deviceService.os == 'iOS') {
      this.renderer.removeClass(document.getElementById('appShow'), 'hidden');
    }
    const user = JSON.parse(localStorage.getItem('currentUser'));
    
    this.headerService.title.subscribe(
      title => {
        setTimeout(() => 
        this.title = title,
        0);
      }
    );

    this.headerService.menuData.subscribe(
      menu => {
        if(menu == 'LandingPage' || menu == 'TilesPage' || menu == 'AddressPage' || menu == 'PaymentPage' || menu == 'LoginPage' || menu == 'RegistrationPage' || menu == 'ProfilePage' || menu == 'ChangePasswordPage' || menu == 'MyOrderPage' || menu == 'StaticPage') {
          if(menu == 'LandingPage') {
            this.renderer.addClass(document.getElementById('container'), 'home');
            this.renderer.addClass(document.getElementById('container'), 'intest');
            this.renderer.addClass(document.getElementById('hidden'), 'hidden');
          } else {
            this.renderer.removeClass(document.getElementById('container'), 'intest');
            this.renderer.removeClass(document.getElementById('container'), 'home');
            this.renderer.removeClass(document.getElementById('hidden'), 'hidden');
          }
          const userDetails = JSON.parse(localStorage.getItem('currentUser'));
          if(userDetails != '' && userDetails != null) {
            setTimeout(() => 
              this.menuData = true,
            0);
          } else {
            setTimeout(() => 
              this.menuData = false,
            0);
          }
        } else if(menu == 'LoginPage') {
          setTimeout(() => 
            this.menuData = false,
          0);
        } 
      }
    );

    if(user != '' && user != null) {
      this.userData.first_name = user.u_first_name;
      this.menuData = true;
    } else {
      this.menuData = false;
    }

    this.year = new Date().getFullYear().toString();
  }

  ngAfterViewInit() {
    // console.log("::::this.pricingData:::: ", this.pricingData);
    var that = this;
    if (!this.pricingData) {
      setTimeout(function() {
        // that.getPricingData();
      }, 3000);
    }
  }

  getPricingData() {
    // if (!this.pricingData) {
      this.userService.price().subscribe(data => {
        if (data.msg_code == 200) {
          // console.log(">>>>>>>>>> ", data," <<<<<<<<<<<<<<<");
          this.pricingData = data.data;
          localStorage.setItem('pricingData', JSON.stringify(this.pricingData ));
        }
      });
    // }
  }

  /* openNav() {
    this.menuClass = true;
    this.overlayStyle = true;
  } */

  /* closeNav() {
    this.menuClass = false;
    this.overlayStyle = false;
  } */
  
  /* goBack() {
    this.location.back();
  } */

  onClick(event) {
    if(document.getElementById('menuTiles').className == "navbar-collapse collapse in") {
      this.renderer.removeClass(document.getElementById('menuTiles'), 'in');
    }
    // if (!this._eref.nativeElement.contains(event.target)) // or some similar check
  }

  logout() {
    this.authenticationService.logout();
    this.menuData = false;
    this.router.navigate(['/login']);
  }

  isActive(instruction: string): boolean {
    return this.router.isActive(instruction, true);
  }

  removeAppinfo() {
    this.renderer.addClass(document.getElementById('appShow'), 'hidden');
  }

  openApp() {
    
  }
}
