import { Component, OnInit, OnDestroy } from "@angular/core";
import { Subscription } from 'rxjs';
import { AlertService } from '../../_services';

@Component({
    selector: 'alert',
    styleUrls: ['./alert.component.scss'],
    templateUrl: 'alert.component.html'
})

export class AlertComponent implements OnInit, OnDestroy {
    private subscription: Subscription;
    message: any;

    constructor(
        private alertService: AlertService
    ) { }

    ngOnInit() {
        this.alertService.getMessage().subscribe(message => {
            if(!message) {
                this.message = '';
                return;
            }
            
            this.message = message;
        });
    }

    removeAlert() {
        this.message = '';
    }

    ngOnDestroy() {
        // this.subscription.unsubscribe();
    }
}