import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { environment } from '../../environments/environment';

// @Injectable() 
export class StaticService {
    constructor(
        private http: HttpClient
    )
    {}

    page(currentPage) {
        var apiUrl:string = '';
        switch(currentPage) {            
            case '/terms-condition':
                    apiUrl = environment.apiUrl + '/app-term-of-use';
                break;
            case '/faq':
                    apiUrl = environment.apiUrl + '/app-faq';
                break;
            case '/privacy-policy':
                    apiUrl = environment.apiUrl + '/app-privacy-policy';
                break;
            /* default:
                    return;
                break; */
        }
        alert(apiUrl);
        // return this.http.get<any>(apiUrl);
    }
}