import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, NavigationEnd } from '@angular/router';
import { Location } from '@angular/common';
import { UserService, HeaderService, AlertService } from '../_services';
import { first, filter } from 'rxjs/operators';

@Component({
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.scss']
})

export class AddressComponent implements OnInit {
  title = 'Snap Tiles - Address';
  addressForm: FormGroup;
  loading: boolean = false;
  submitted: boolean = false;
  returnUrl: string = '';
  error: string = '';
  readonly: boolean = true;
  address: any = {
    access_token: '',
    address_1: '',
    address_2: '',
    city: '',
    state: '',
    postcode: '',
    country_id: 233
  }

  constructor (
    private formBuilder: FormBuilder,
    private router: Router,
    private userService: UserService,
    private alertService: AlertService,
    private headerService: HeaderService,
    private _location: Location
  ) {
    const address = JSON.parse(localStorage.getItem('userAddress'));
    if(address != null) {
      this.address.address_1 = address.address_1;
      this.address.address_2 = address.address_2;
      this.address.city = address.city;
      this.address.state = address.state;
      this.address.postcode = address.postcode;
      this.address.country_id = address.country_id;
    }
  }

  ngOnInit() {
    this.headerService.setTitle('Add Address');
    
    this.router.events.subscribe((evt) => {
        if (!(evt instanceof NavigationEnd)) {
            return;
        }
        window.scrollTo(0, 0)
    });

    let user = JSON.parse(localStorage.getItem('currentUser'));
    if(user != '' && user != null) {
      this.headerService.setMenu('true', 'AddressPage');
    } else {
      this.headerService.setMenu('false', 'AddressPage');
    }

    this.addressForm = this.formBuilder.group({
      address_1: ['', Validators.required],
      address_2: [''],
      city: ['', Validators.required],
      state: ['', Validators.required],
      postcode: ['', Validators.required] 
    })
  }

  get f() {
    return this.addressForm.controls;
  }

  number(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if(charCode > 33 && (charCode < 48 || charCode > 57 )) {
      return false;
    }
    return true;
  }

  onSubmit() {
    this.submitted = true;
    
    if(this.addressForm.invalid) {
      return;
    }

    this.loading = true;
    const user = JSON.parse(localStorage.getItem('currentUser'));

    this.address.access_token = user.access_token;
    this.address.address_1 = this.f.address_1.value;
    this.address.address_2 = this.f.address_2.value;
    this.address.city = this.f.city.value;
    this.address.state = this.f.state.value;
    this.address.postcode = this.f.postcode.value;
    this.address.country_id = 223;

    // const address = JSON.parse(localStorage.getItem('userAddress'));

    /*if(address != null) {
      if(address.address_1 == this.address.address_1 
        && address.city == this.address.city 
        && address.state == this.address.state 
        && address.postcode == this.address.postcode) {
          this.submitted = false;
          this.loading = false;
          this.alertService.info('Nothing to be update.');
          return;
        }
    }*/
    
    this.userService.updateAddress(this.address)
    .pipe(first())
    .subscribe(data => {
      if(data && data.msg_code == 812) {
        localStorage.setItem('userAddress', JSON.stringify(data.data));
        this.alertService.success(data.message);
        const back = localStorage.getItem('back');
          if(typeof back !== 'undefined' && back !== null) {
            this.router.navigate(['/profile']);
          } else {
            let tiles = JSON.parse(localStorage.getItem('tiles'));
            let quantity = 0;
            if(typeof tiles !== 'undefined' && tiles !== null) {
              if(tiles.length > 0) {
                tiles.forEach(tile => {
                  tile.tile_color = localStorage.getItem('selectedTile');
                  quantity += tile.qty;
                });

                if(quantity >= 4) {
                  let order_id = JSON.parse(localStorage.getItem('order_id'));
                  if(order_id == null) {
                    order_id = '';
                  }
                  this.userService.placeorder(tiles, this.address.access_token, order_id, {})
                  .pipe(first())
                  .subscribe(data => {
                    if(data && data.msg_code == 820) {
                      localStorage.setItem('order_id', data.data.order_id);
                      localStorage.setItem('order', JSON.stringify(data.data));
                      this.router.navigate(['/payment']);    
                    } else {
                      this.router.navigate(['/tiles']);      
                    }
                  });
                } else {
                  this.router.navigate(['/tiles']);  
                }
              } else {
                this.router.navigate(['/tiles']);
              }
            } else {
              this.router.navigate(['/tiles']);
            }
          } 
      } else {
        this.alertService.error(data.message);
      }
      
    }, error => {
      this.loading = false;
      this.alertService.error(error);
    });
  }
}
