import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';

@Injectable()
export class HeaderService {
    public title = new BehaviorSubject('');
    public menuData = new BehaviorSubject('');

    constructor() {}

    setMenu(menuData, pageComponent) {
        this.menuData.next(pageComponent);
    }

    setTitle(title) {
        this.title.next(title);
    }
}