import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from "@angular/router";

import { UserService } from '../_services';
import { first } from 'rxjs/operators';

@Component({
  templateUrl: './order-details.component.html',
  styleUrls: ['./order-details.component.scss']
})
export class OrderDetailsComponent implements OnInit {
  title = 'Snap Tiles - Order Details';
  order_id: string = '';
  mainLoader: boolean = false;
  showSuccess = false;

  orderDetails: any = {
    order_id: '',
    timestamp: '',
    total_amt: '',
    address: '',
    payment_method: '',
    order_status: ''
  };

  constructor (
    private route: ActivatedRoute,
    private userService: UserService
  ) {
    this.route.params.subscribe(params => 
      this.order_id = params.order_id);
  }

  ngOnInit() {
    const showSuccessInfo = JSON.parse(localStorage.getItem('showSuccess'));
    if(showSuccessInfo == true){
      localStorage.removeItem('showSuccess');
      // this.showSuccess = true;
    }

    this.mainLoader = true;
    let user = JSON.parse(localStorage.getItem('currentUser'));

    let orderDetail: any = {
      order_id: this.order_id,
      access_token: user.access_token
    };
    this.userService.orderDetail(orderDetail)
    .pipe(first())
    .subscribe(data => {
      if(data && data.msg_code == 200) {
        
        this.orderDetails = data.data;
        this.mainLoader = false;
      } else {
        this.mainLoader = false;
      }
    });
  }
}
