import { Injectable } from "@angular/core";
import { Subject, Observable } from 'rxjs';
import { Router, NavigationStart } from '@angular/router';

@Injectable()
export class AlertService {
    private subject = new Subject<any>();
    private keepAfterNavigationChange: boolean = false;

    constructor(
        private router: Router
    ) {
        router.events.subscribe(event => {
            if(event instanceof NavigationStart) {
                if(this.keepAfterNavigationChange) {
                    this.keepAfterNavigationChange = false;
                } else {
                    this.subject.next();
                }
            }
        });
    }

    success(message: string, keepAfterNavigationChange = false) {
        this.keepAfterNavigationChange = keepAfterNavigationChange;
        this.subject.next({type: 'success', text: message});
    }

    error(message: string, keepAfterNavigationChange = false) {
        console.log(message);
        this.keepAfterNavigationChange = keepAfterNavigationChange;
        this.subject.next({type: 'error', text: message});
    }

    info(message: string, keepAfterNavigationChange = false) {
        this.keepAfterNavigationChange = keepAfterNavigationChange;
        this.subject.next({type: 'info', text: message});
    }

    getMessage(): Observable<any> {
        return this.subject.asObservable();
    }
}