import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { Router } from '@angular/router';
import { UserService, HeaderService, AlertService } from '../_services';
import { first } from 'rxjs/operators';

@Component({
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
  title = 'Snap Tiles - Change Password';
  changePasswordForm: FormGroup;
  submitted: boolean = false;
  loading: boolean = false;
  error: string = '';

  changePassword: any = {
    access_token: '',
    currentpassword: '',
    newpassword: '',
    confirmpassword: '',
  }

  constructor (
    private formBuilder: FormBuilder,
    private router: Router,
    private userService: UserService,
    private alertService: AlertService,
    private headerService: HeaderService
  ) {

  }

  ngOnInit() {
    this.headerService.setTitle('Change Password');
    this.headerService.setMenu('false', 'ChangePasswordPage');
    this.changePasswordForm = this.formBuilder.group({
      currentpassword: ['', Validators.required],
      newpassword: ['', Validators.required],
      confirmpassword: ['', Validators.required]
    }, {validator: this.checkPasswords});
  }

  get f() {
    return this.changePasswordForm.controls;
  }

  checkPasswords(group: FormGroup) {
    return group.controls.newpassword.value === group.controls.confirmpassword.value ? null : { notSame: true }     
  }

  onSubmit() {
    this.submitted = true;

    if(this.changePasswordForm.invalid) {
      return
    }

    this.loading = true;

    const user = JSON.parse(localStorage.getItem('currentUser'));

    this.changePassword.access_token = user.access_token;
    this.changePassword.currentpassword = this.f.currentpassword.value;
    this.changePassword.newpassword = this.f.newpassword.value;
    this.changePassword.confirmpassword = this.f.confirmpassword.value;

    this.userService.changePassword(this.changePassword)
    .pipe(first())
    .subscribe(data => {
      if(data.msg_code == 608) {
        let control: AbstractControl = null;
        this.alertService.success(data.message);
        this.changePasswordForm.reset();
        this.changePasswordForm.markAsUntouched();
        Object.keys(this.changePasswordForm.controls).forEach((name) => {
          control = this.changePasswordForm.controls[name];
          control.setErrors(null);
        });
      } else {
        this.alertService.error(data.message);
      }
      this.loading = false;
    }, error => {
      this.loading = false;
      this.alertService.error(error);
    });
  }
}
