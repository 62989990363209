import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Title, Meta } from '@angular/platform-browser';
import { environment } from '../../environments/environment';
import { HeaderService, AlertService, AuthenticationService } from '../_services';

@Component({
    templateUrl: './static-pages.component.html',
    styleUrls: ['./static-pages.component.scss']
})
export class StaticPagesComponent implements OnInit {
    title = 'Snap Tiles';
    currentUrl: string;
    staticPages = ['/faq', '/terms-condition', '/privacy-policy', '/privacy', '/about-us', '/contact-us', '/instagram', '/facebook'];
    apiUrl: string = '';
    content: string = '';
    contactPage: boolean = false;
    mainLoader: boolean = false;

    constructor(
        private router: Router,
        private http: HttpClient,
        private headerService: HeaderService,
        private alertService: AlertService,
        private authenticationService: AuthenticationService,
        private titleService: Title,
        private meta: Meta
    ) { }

    ngOnInit() {
        this.currentUrl = this.router.url;
        this.headerService.setMenu('false', 'StaticPage');

        let initial_url = this.router.url.split('?');

        if (this.staticPages.indexOf(initial_url[0]) > -1) {
            this.mainLoader = true;
            switch (initial_url[0]) {
                case '/terms-condition':
                    this.title = 'Terms of Use';
                    this.apiUrl = environment.staticPages + '/app-term-of-use';
                    this.titleService.setTitle('Terms of Use | Magnetic Photo Tiles App');
                    this.meta.updateTag({name: 'description', content: 'We hand manufacture with care every Snaptile out of our Irvine, California warehouse. Gather the family together and Get Snappin! ORDER NOW.'});
                    break;
                case '/faq':
                    this.title = 'FAQ';
                    this.apiUrl = environment.staticPages + '/app-faq';
                    this.titleService.setTitle('FAQ | Magnetic Photo Tiles App');
                    this.meta.updateTag({name: 'description', content: 'We hand manufacture with care every Snaptile out of our Irvine, California warehouse. Gather the family together and Get Snappin! ORDER NOW.'});
                    break;
                case '/privacy':
                        this.title = 'Privacy Policy';
                        this.apiUrl = environment.staticPages + '/app-privacy-policy';
                        this.titleService.setTitle('Privacy Policy | Magnetic Photo Tiles App');
                        this.meta.updateTag({name: 'description', content: 'We hand manufacture with care every Snaptile out of our Irvine, California warehouse. Gather the family together and Get Snappin! ORDER NOW.'});
                        break;
                case '/privacy-policy':
                    this.title = 'Privacy Policy';
                    this.apiUrl = environment.staticPages + '/app-privacy-policy';
                    this.titleService.setTitle('Privacy Policy | Magnetic Photo Tiles App');
                    this.meta.updateTag({name: 'description', content: 'We hand manufacture with care every Snaptile out of our Irvine, California warehouse. Gather the family together and Get Snappin! ORDER NOW.'});
                    break;
                case '/about-us':
                    this.title = 'About Us';
                    this.apiUrl = environment.staticPages + '/app-about-us';
                    this.titleService.setTitle('About Us | Magnetic Photo Tiles App');
                    this.meta.updateTag({name: 'description', content: 'We hand manufacture with care every Snaptile out of our Irvine, California warehouse. Gather the family together and Get Snappin! ORDER NOW.'});
                    break;
                case '/contact-us':
                    this.title = 'Contact Us';
                    this.titleService.setTitle('Contact Us | Magnetic Photo Tiles App');
                    this.meta.updateTag({name: 'description', content: 'We hand manufacture with care every Snaptile out of our Irvine, California warehouse. Gather the family together and Get Snappin! ORDER NOW.'});
                    this.contactPage = true;
                    this.mainLoader = false;
                    // this.apiUrl = environment.staticPages + '/app-privacy-policy';
                    break;
                default:
                    this.titleService.setTitle('Magnetic Photo Tiles that snap together in seconds | Photo Wall Art | Stickable Photo Tiles');
                    this.meta.updateTag({name: 'description', content: 'These are NOT your ordinary Photo Tiles! Snaptiles are the first magnetic photo tiles that hang in seconds. Hang one and Snap on the others!'});
                    window.close();
                    return;
                    break;
            }

            if (!this.contactPage) {
                this.http.get(this.apiUrl, { responseType: 'text' })
                    .subscribe(data => {
                        this.content = data;
                        this.mainLoader = false;
                    }, err => {
                        this.mainLoader = false;
                        this.alertService.error('We can\'t process this page right now');
                    });
            }
        } else {

            let initial_url = this.router.url.split('#');

            if (initial_url[0] == '/facebook') {
                if (this.staticPages.indexOf(initial_url[0]) > -1) {
                    let facebook_access_token: any = initial_url[1].split('=');
                    let ac_tc_str = facebook_access_token[1].split('&');;
                    localStorage.setItem('facebook_access_token_set', 'true');
                    localStorage.setItem('facebook_access_token', ac_tc_str[0]);
                    window.close();
                }
            } else {
                if (this.staticPages.indexOf(initial_url[0]) > -1) {
                    let instagram_access_token = initial_url[1].split('=');
                    localStorage.setItem('instagram_access_token', instagram_access_token[1]);
                    localStorage.setItem('instagram_access_token_set', 'true');
                    window.close();
                }
            }
            // console.log(initial_url);
            // if (this.staticPages.indexOf(initial_url[0]) > -1) {
            //     let instagram_access_token = initial_url[1].split('=');
            //     localStorage.setItem('instagram_access_token', instagram_access_token[1]);
            //     window.close();
            // }
        }
    }
}
