import { Component, OnInit, Renderer2, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { DialogComponent, DialogService } from "ng6-bootstrap-modal";
import { HttpClient, HttpParams } from '@angular/common/http';
/* import { CropperOption } from 'ngx-cropper';
import { ImageCropperComponent, CropperSettings } from 'ngx-img-cropper'; */
// import { NgxCropperjsComponent, ImageCropperResult } from 'ngx-cropperjs';
import { CropperComponent, ImageCropperResult } from 'angular-cropperjs';
import Cropper from 'cropperjs';
import { UserService } from '../../_services';
import { DomSanitizer } from '@angular/platform-browser';
import { first } from 'rxjs/operators';
declare var $: any;
import Croppie from 'croppie'
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
    selector: 'adjust',
    styleUrls: ['./adjust.component.scss'],
    templateUrl: 'adjust.component.html'
})

export class AdjustComponent extends DialogComponent<MessageModel, boolean> implements MessageModel, OnInit, AfterViewInit {
    id: string;
    message: string;
    imageUrl = '';
    cropImage = '';
    mainLoader: boolean = false;
    scale = 1;
    img_ele: any;
    x_img_ele: any;
    y_img_ele: any;
    canvasitem: any;
    canvasTransformMatrix: any;
    basic: any;
    croppie: any;
    isMobile: boolean = false;
    // @ViewChild('cropperImg', {static: false}) public cropperImg: ElementRef;
    constructor(
        dialogService: DialogService,
        private userService: UserService,
        private http: HttpClient,
        private renderer: Renderer2,
        private sanitizer: DomSanitizer,
        public deviceService: DeviceDetectorService,
    ) {
        super(dialogService);
    }

    ngAfterViewInit() {
       
        
    }

    ngOnInit() {
        this.isMobile = this.deviceService.isMobile();
        this.imageUrl = this.message;
        let wind: any = window;
        this.croppie = wind.Croppie;
        
        let that = this;
        setTimeout(() => {
            that.basic = new Croppie(document.getElementById('demo-basic'), {
                viewport: { width: 250, height: 250 },
                boundary: { width: 250, height: 250 },
                showZoomer: false,
                url: `${that.message}`,
                get:function(event) {
                    console.log(event)
                }
            });
        }, 0);
    }

    cropMe() {
        this.mainLoader = true;
        let wind: any = window;
        let croppie = wind.Croppie;
        let res = this.basic.get();
        let tiles = JSON.parse(localStorage.getItem('tiles'));
        this.userService.croppingImage(this.imageUrl, JSON.stringify(res))
            .pipe(first())
            .subscribe(data => {
                if (data && data.msg_code == 200) {
                    for (var i = 0; i <= tiles.length; i++) {
                        if (i == parseInt(this.id)) {
                            tiles[i].cropUrl = data.data.cropped_url;
                            break;
                        }
                    }
                    localStorage.setItem('tiles', JSON.stringify(tiles));
                    this.mainLoader = false;
                    this.renderer.removeClass(document.body, 'modal-open');
                    this.close();
                } else {
                    this.mainLoader = false;
                }
            });

        // var canvas = document.getElementsByTagName('canvas')[0];

        // var jpegUrl = canvas.toDataURL("image/jpeg");
        // var pngUrl = canvas.toDataURL();
        // console.log(document.getElementsByTagName('canvas'));
        // console.log(this.canvasTransformMatrix);
        
        // this.mainLoader = true;
        // let tiles = JSON.parse(localStorage.getItem('tiles'));
        // this.userService.uploadImageBase64(this.cropImage)
        //     .pipe(first())
        //     .subscribe(data => {

        //         if (data && data.msg_code == 200) {
        //             for (var i = 0; i <= tiles.length; i++) {
        //                 if (i == parseInt(this.id)) {
        //                     tiles[i].cropUrl = data.data.url;
        //                     break;
        //                 }
        //             }
        //             localStorage.setItem('tiles', JSON.stringify(tiles));
        //             this.mainLoader = false;
        //             this.renderer.removeClass(document.body, 'modal-open');
        //             this.close();
        //         } else {
        //             this.mainLoader = false;techno

        //         }
        //     });
    }

    mouseWheel(e) {
        if (e.deltaY > 0) {
            this.scale = 0.5;
        } else {
            this.scale = 1.5;
        }

        this.img_ele = document.getElementById('drag-img');
        let pre_width = this.img_ele.getBoundingClientRect().width,
            pre_height = this.img_ele.getBoundingClientRect().height;
        this.img_ele.style.width = (pre_width * this.scale) + 'px';
        this.img_ele.style.height = (pre_height * this.scale) + 'px';
        this.img_ele = null;
    }

    closeAdjust() {
        this.renderer.removeClass(document.body, 'modal-open');
        this.close();
    }

    mousedown(event) {
        this.img_ele = event.target;
        this.x_img_ele = event.clientX - document.getElementById('drag-img').offsetLeft;
        this.y_img_ele = event.clientY - document.getElementById('drag-img').offsetTop;
    }
    mouseup(event) {
        this.img_ele = null;
    }

    mousemove(event) {
        var x_cursor = event.clientX;
        var y_cursor = event.clientY;
        if (this.img_ele && this.img_ele !== null) {
            this.img_ele.style.left = (x_cursor - this.x_img_ele) + 'px';
            this.img_ele.style.top = (event.clientY - this.y_img_ele) + 'px';
        }
    }
}

export interface MessageModel {
    id: string;
    message: string;
}