import { Routes, RouterModule } from '@angular/router';

import { LandingComponent } from './landing';
import { TilesComponent } from './tiles';
import { LoginComponent } from './login';
import { RegistrationComponent } from './registration';
import { ForgotPasswordComponent } from './forgot-password';
import { ResetPasswordComponent } from './reset-password';
import { ChangePasswordComponent } from './change-password';
import { EmailVerificationComponent } from './email-verification';
import { MobileVerificationComponent } from './mobile-verification';
import { ProfileComponent } from './profile';
import { AddressComponent } from './address';
import { MyOrdersComponent } from './my-orders';
import { OrderStatusComponent } from './order-status';
import { OrderDetailsComponent } from './order-details';
import { PaymentComponent } from './payment';
import { StaticPagesComponent } from './static-pages';
import { GuestcheckoutComponent } from './guestcheckout/guestcheckout.component';
import { AuthGuard } from './_guards';
// import { Role } from './_models';

const appRoutes: Routes = [
    {
        path: '',
        component: LandingComponent,
    },
    {
        path: 'tiles',
        component: TilesComponent
    },
    {
        path: 'login',
        component: LoginComponent
    },
    {
        path: 'registration',
        component: RegistrationComponent
    },
    {
        path: 'forgot-password',
        component: ForgotPasswordComponent
    },
    {
        path: 'reset-password',
        component: ResetPasswordComponent
    },
    {
        path: 'change-password',
        component: ChangePasswordComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'email-verification',
        component: EmailVerificationComponent
    },
    {
        path: 'mobile-verification',
        component: MobileVerificationComponent
    },
    {
        path: 'profile',
        component: ProfileComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'address',
        component: AddressComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'my-orders',
        component: MyOrdersComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'order-status',
        component: OrderStatusComponent
        /*, canActivate: [AuthGuard]*/
    },
    {
        path: 'order-details/:order_id',
        component: OrderDetailsComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'payment',
        component: PaymentComponent
        /*, canActivate: [AuthGuard]*/
    },
    {
        path: 'terms-condition',
        component: StaticPagesComponent
    },
    {
        path: 'privacy-policy',
        component: StaticPagesComponent
    },
    {
        path: 'privacy',
        component: StaticPagesComponent
    },
    {
        path: 'about-us',
        component: StaticPagesComponent
    },
    {
        path: 'contact-us',
        component: StaticPagesComponent
    },
    {
        path: 'guest-checkout',
        component: GuestcheckoutComponent
    },
    {
        path: 'faq',
        component: StaticPagesComponent
    },
    {        
        path: 'instagram',
        component: StaticPagesComponent
    },
    {        
        path: 'facebook',
        component: StaticPagesComponent
    },

    // otherwise redirect to home
    { path: '**', redirectTo: '' }
];

export const routing = RouterModule.forRoot(appRoutes, {useHash: false});