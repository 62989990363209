import { Component, OnInit, HostListener } from '@angular/core';
import { HeaderService, UserService, AuthenticationService, AlertService } from '../_services';
import { Router } from '@angular/router';

@Component({
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements OnInit {
  title = 'Snap Tiles - Home';
  footerNoFixed:boolean = false;
  tileCost: any={four_tile_price:''};
  year: any;
  
  constructor (
    private headerService: HeaderService,
    private userService: UserService
  ) {
    
  }

  ngOnInit() {
    // this.tileCosts();
    const user = JSON.parse(localStorage.getItem('currentUser'));
    if(user != '' && user != null) {
      this.headerService.setMenu('true', 'LandingPage');
    } else {
      this.headerService.setMenu('false', 'LandingPage');
    }
    this.year = new Date().getFullYear().toString();
  }
  

  tileCosts() {
    if (this.tileCost.four_tile_price=='') {
      this.userService.price()
      .subscribe(data => {
        if (data.msg_code == 200) {
          // console.log(">>>>>>>>>> ", data," <<<<<<<<<<<<<<<");
          this.tileCost = data.data;
        }
      });
    }
  }

  @HostListener('window:scroll', ['$event'])
  onScroll(event: any) {
    /*this.footerNoFixed = false;
    
    if ((window.innerHeight + window.scrollY) == document.body.offsetHeight) {
      this.footerNoFixed = true;
    } else {
      this.footerNoFixed = false;
    }*/
  } 
}
