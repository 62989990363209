import { Component, OnInit, Renderer2, ViewChild, ElementRef, Directive, EventEmitter, Output, HostListener } from '@angular/core';
import { DialogService } from 'ng6-bootstrap-modal';
import { AuthService, FacebookLoginProvider } from 'angular-6-social-login';
import { DeviceDetectorService } from 'ngx-device-detector';

import { HeaderService, UserService, AuthenticationService, AlertService } from '../_services';
import { UploadComponent, AdjustComponent, ConfirmUploadComponent } from '../_directives';
import { Router, NavigationEnd } from '@angular/router';
import { environment } from 'src/environments/environment';
import { first } from 'rxjs/operators';
import { CropperComponent } from 'angular-cropperjs';
import { promise } from 'protractor';
import { HttpEventType, HttpResponse, HttpClient } from '@angular/common/http';

@Component({
  templateUrl: './tiles.component.html',
  styleUrls: ['./tiles.component.scss']
})

@Directive({
  selector: '[appDragDrop]'
})

export class TilesComponent implements OnInit {
  @Output() onFileDropped = new EventEmitter<any>();

  title: string = 'Selected Tiles';

  width: any = '';
  height: any = '';
  selectedTilesColor: string = '#d4e1ec';
  overlay: string = 'none';
  instaLogin: boolean = false;
  isDesktopDevice: boolean = false;
  showInDevice: boolean = false;
  ifAvailable: boolean = true;
  submitButton: boolean = false;
  mirredImage: boolean = false;
  loading: boolean = false;
  mainLoader: boolean = false;
  photoTiles: any = [];
  isMobile: boolean = false;
  quantityCheck: boolean = false;
  quantity: number = 0;
  additionalTileCost: string = '0';
  progress: boolean = false;
  progressPercentage: string = '0%';
  numberOfFiles: number = 0;
  currentFileUpload: number = 0;
  imageFileTypes: any = ['image/jpeg', 'image/jpg', 'image/JPEG', 'image/JPG', 'image/png', 'image/PNG'];

  tilesList: any = [
    {
      name: 'White',
      selected: false,
      color: 'white',
      right: 'rgba(255, 255, 255, 0.6)',
      bottom: 'rgba(255, 255, 255, 1)',
      tileColor: 'rgba(255, 255, 255, 1)'
    },
    {
      name: 'Black',
      selected: false,
      color: 'black',
      right: 'rgba(0, 0, 0, 0.6)',
      bottom: 'rgba(0, 0, 0, 1)',
      tileColor: 'rgba(0, 0, 0, 1)'
    },
    {
      name: 'Red',
      selected: false,
      color: 'red',
      right: 'rgba(255, 0, 0, 0.6)',
      bottom: 'rgba(255, 0, 0, 1)',
      tileColor: 'rgba(255, 0, 0, 1)'
    },
    {
      name: 'Blue',
      selected: false,
      color: 'blue',
      right: 'rgba(0, 102, 204, 0.6)',
      bottom: 'rgba(0, 102, 204, 1)',
      tileColor: 'rgba(0, 102, 204, 1)'
    },
    {
      name: 'Orange',
      selected: false,
      color: 'orange',
      right: 'rgba(255, 165, 0, 0.6)',
      bottom: 'rgba(255, 165, 0, 1)',
      tileColor: 'rgba(255, 165, 0, 1)'
    },
    {
      name: 'Yellow',
      selected: false,
      color: 'yellow',
      right: 'rgba(255, 255, 0, 0.6)',
      bottom: 'rgba(255, 255, 0, 1)',
      tileColor: 'rgba(255, 255, 0, 1)'
    },
    {
      name: 'Purple',
      selected: false,
      color: 'purple',
      right: 'rgba(128, 0, 128, 0.6)',
      bottom: 'rgba(128, 0, 128, 1)',
      tileColor: 'rgba(128, 0, 128, 1)'
    },
    {
      name: 'Green',
      selected: false,
      color: 'green',
      right: 'rgba(0,128,0, 0.6)',
      bottom: 'rgba(0, 128, 0, 1)',
      tileColor: 'rgba(0, 128, 0, 1)'
    },
    /* {
      name: 'Brown',
      selected: false,
      color: 'brown',
      right: 'rgba(165, 42, 42, 0.6)',
      bottom: 'rgba(165, 42, 42, 1)',
      tileColor: 'rgba(165, 42, 42, 1)'
    },
    {
      name: 'Grey',
      selected: false,
      color: 'grey',
      right: 'rgba(128, 128, 128, 0.6)',
      bottom: 'rgba(128, 128, 128, 1)',
      tileColor: 'rgba(128, 128, 128, 1)'
    }, */
    {
      name: 'Pink',
      selected: false,
      color: 'pink',
      right: 'rgba(255, 192, 203, 0.6)',
      bottom: 'rgba(255, 192, 203, 1)',
      tileColor: 'rgba(255, 192, 203, 1)'
    },
    /* {
      name: 'Mirror Edge',
      selected: false,
      color: '#d4e1ec',
      right: 'rgba(212, 225, 236, 0.6)',
      bottom: 'rgba(212, 225, 236, 1)',
      tileColor: 'rgba(212, 225, 236, 1)'
    } */
  ];

  imageUrl = '';
  cropImage = '';
  inOutClass: boolean = false;

  lengthFiles: number = 0;
  currentIndex: number = 0;
  interval: any;

  pricingData: any = {};

  // @ViewChild('cropperImg', {static: false}) public cropperImg: ElementRef;
  constructor(
    private headerService: HeaderService,
    private dialog: DialogService,
    private socialAuthService: AuthService,
    private userService: UserService,
    private authenticationService: AuthenticationService,
    private renderer: Renderer2,
    private router: Router,
    private deviceService: DeviceDetectorService,
    public alertService: AlertService,
    private http: HttpClient
  ) {
      this.checkFackbookToken();
  }

  checkFackbookToken() {
    this.interval = setInterval(() => {
      let access_token = localStorage.getItem('facebook_access_token_set');
      if (access_token) {
        setTimeout(() => {
          this.dialog.removeAll();
          this.showDialog();
        }, 0);
      }
      let insta_access_token = localStorage.getItem('instagram_access_token_set');
      if (insta_access_token) {
        setTimeout(() => {
          this.dialog.removeAll();
          this.showDialog();
        }, 0);
      }


    }, 3000);

  }


  //Dragover listener
  @HostListener('dragover', ['$event']) onDragOver(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    // this.background = '#9ecbec';
    // this.opacity = '0.8'
  }

  //Dragleave listener
  @HostListener('dragleave', ['$event']) public onDragLeave(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    // this.background = '#f5fcff'
    // this.opacity = '1'
  }

  //Drop listener
  @HostListener('drop', ['$event']) public ondrop(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    // this.background = '#f5fcff'
    // this.opacity = '1'
    let files = evt.dataTransfer.files;
    if (files.length > 0) {
      this.uploadImage(evt.dataTransfer.files);
    }
  }

  ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0)
    });

    this.isMobile = this.deviceService.isMobile();
    if (this.isMobile) {
      // this.renderer.addClass('.tileShow', 'hover');
    } else {
      // this.renderer.removeClass('.tileShow', 'hover');
    }
    let selectedTile = localStorage.getItem('selectedTile');
    if (selectedTile != null) {
      this.selectedTiles(selectedTile);
    } else {
      this.selectedTiles(this.selectedTilesColor);
    }

    let user = JSON.parse(localStorage.getItem('currentUser'));
    if (user != '' && user != null) {
      this.headerService.setMenu('true', 'TilesPage');
    } else {
      this.headerService.setMenu('false', 'TilesPage');
    }

    this.isDesktopDevice = this.deviceService.isDesktop();


    if (!this.isDesktopDevice) {
      this.showInDevice = true;
    }
    this.headerService.setTitle(this.title);

    let instagram = localStorage.getItem('instagram_access_token');
    if (instagram != '') {
      this.instaLogin = true;
    } else {
      this.instaLogin = false;
    }

    let tiles = JSON.parse(localStorage.getItem('tiles'));

    if (typeof tiles !== 'undefined' && tiles !== null) {
      if (tiles.length == 0) {
        this.submitButton = false;
      } else {
        this.submitButton = true;
      }

      tiles.forEach(tile => {
        this.quantity += tile.qty;
      });

      this.photoTiles = tiles;
    } else {
      this.submitButton = false;
    }

    this.quantityCheck = this.checkQuantity();

    this.additionalTileCosts();


    this.authenticationService.openFbPopup$.subscribe(
      selectedCert => {
        this.checkFackbookToken();
      });

    // showDialog

    this.pricingData = JSON.parse(localStorage.getItem('pricingData'));
  }

  mouseHover(inOut) {
    if (inOut == 'in') {
      this.inOutClass = true;
      // this.renderer.addClass('ul.tileAdd', 'hover');
    } else {
      this.inOutClass = false;
      // this.renderer.removeClass('.tileShow', 'hover');
    }
  }

  additionalTileCosts() {
    this.userService.price()
      .subscribe(data => {
        if (data.msg_code == 200) {
          this.additionalTileCost = data.data.additional_per_tile_price;
          this.pricingData = data.data;
          localStorage.setItem('pricingData', JSON.stringify(this.pricingData ));
        }
      });
  }

  selectedTiles(color) {
    if (color == '#d4e1ec') {
      this.mirredImage = true;
    } else {
      this.mirredImage = false;
    }
    localStorage.setItem('selectedTile', color);
    this.selectedTilesColor = color;
  }

  showDialog() {
    let activeTab = 'fb'; 
    let local_access_token = localStorage.getItem('facebook_access_token_set');

    if (local_access_token) {
      activeTab = 'fb';
      console.log('removeItem(facebook_access_token_set');
      localStorage.removeItem('facebook_access_token_set');
      clearInterval(this.interval);
    }



    let insta_local_access_token = localStorage.getItem('instagram_access_token_set');

    if (insta_local_access_token) {
      activeTab = 'in';
      console.log('removeItem(instagram_access_token_set');
      localStorage.removeItem('instagram_access_token_set');
      clearInterval(this.interval);
    }

    this.renderer.addClass(document.body, 'modal-open');
    this.overlay = 'block';
    let disposable = this.dialog.addDialog(UploadComponent, {
      title: 'Confirm title',
      message: 'Confirm message',
      activeTab: activeTab
    })
      .subscribe(() => {
        this.overlay = 'none';
        this.renderer.removeClass(document.body, 'modal-open');
        const tiles = JSON.parse(localStorage.getItem('tiles'));
        if (typeof tiles !== 'undefined' && tiles !== null) {
          this.photoTiles = tiles;
          this.submitButton = true;
        } else {
          this.submitButton = false;
        }
        this.quantity = 0;
        this.photoTiles.forEach(tile => {
          this.quantity += tile.qty;
        });
        this.quantityCheck = this.checkQuantity();
      });
    //We can close dialog calling disposable.unsubscribe();
    //If dialog was not closed manually close it by timeout
    /* setTimeout(()=>{
        disposable.unsubscribe();
    },15000); */
  }

  socialSignIn(socialPlatform: string) {
    let socialPlatformProvider;
    if (socialPlatform == 'facebook') {
      socialPlatformProvider = FacebookLoginProvider.PROVIDER_ID

      this.socialAuthService.signIn(socialPlatformProvider).then(
        userData => {

          this.userService.getPhotos(userData.token, userData.id);
        }
      );
    } else {
      this.userService.instagramLogin();
    }
  }

  removeTile(i) {
    const tiles = JSON.parse(localStorage.getItem('tiles'));
    tiles.splice(i, 1);

    if (tiles.length == 0) {
      this.submitButton = false;
    } else {
      this.submitButton = true;
    }

    this.quantity = 0;
    tiles.forEach(tile => {
      this.quantity += tile.qty;
    });

    this.quantityCheck = this.checkQuantity();

    this.photoTiles = tiles;
    localStorage.setItem('tiles', JSON.stringify(tiles));
  }

  async uploadImage(files: File[]) {
    this.mainLoader = true;
    if (files.length == 0) {
      this.mainLoader = false;
      return;
    }
    if (files.length > 0) {
      // Custom Method
      this.lengthFiles = files.length;
      this.customPromise(this.lengthFiles, 0, files);

      // Alternet Method
      /* for (const file of files) {
        console.log(file);
        var mimeType = file.type;
        if (this.imageFileTypes.indexOf(mimeType) == -1) {
          this.mainLoader = false;
          // this.message = "Only images are supported.";
          return;
        } else {
          
          await this.executeFile(file);
        }
      } */
    }
  }

  customPromise(length, currentIndex, files) {
    length = length - 1;
    if (currentIndex <= length) {
      var mimeType = files[currentIndex].type;
      if (this.imageFileTypes.indexOf(mimeType) == -1) {
        console.log('mimeType:::::::::::::::::::::', mimeType);
        this.mainLoader = false;
        this.currentIndex = 0;
        var that = this;
        this.renderer.addClass(document.body, 'modal-open');
        this.overlay = 'block';
        let disposable = this.dialog.addDialog(ConfirmUploadComponent, {
            title: 'File not supported',
            confirmtext: 'Ok',
            canceltext: 'Close',
            message: 'Please upload only images with allowed extentions (jpeg, jpg, JPEG, JPG, png, PNG).'
          }).subscribe(async (isConfirmed) => {
              if (isConfirmed) {
                this.mainLoader = false;
              }
              this.overlay = 'none';
          });
        /*if (length > 0) {
          this.customPromise(this.lengthFiles, this.currentIndex, files);
        }*/
      } else {
        this.executeFile(files);
      }
    }else if (this.lengthFiles == currentIndex) {
      this.mainLoader = false;
      this.lengthFiles = 0;
      this.currentIndex = 0;
    }
  }

  /* executeFile(file) {
    let that = this;
    let fr:any = new FileReader;
    fr.readAsDataURL(file);
    fr.onload = function(e) { // when file has loaded
    var img = new Image();
    img.src = e.target.result;
    img.onload = async function() {
        if(img.width < 1000 || img.height < 1000) {
          that.mainLoader = false;
          that.renderer.addClass(document.body, 'modal-open');
          that.overlay = 'block'; 
          let disposable = await that.dialog.addDialog(ConfirmUploadComponent, {
            message: 'This photo is actually pretty small. It will probably make a blurry tile!'
          })
          .subscribe(async (isConfirmed)=> {
            if(isConfirmed) {
              that.mainLoader = true;
              let data = await that.keepImage(file);
              console.log(data);
            }
            that.overlay = 'none';
          });
        } else {
          let data1 = await that.keepImage(file);
        }
      };
    }
  } */

  executeFile(files) {
    this.numberOfFiles = files.length;
    this.mainLoader = true;
    let that = this;
    let fr: any = new FileReader;
    fr.readAsDataURL(files[this.currentIndex]);
    fr.onload = function (e) { // when file has loaded
      var img = new Image();
      img.src = e.target.result;
      img.onload = async function () {
        if (img.width < 1000 || img.height < 1000) {
          that.mainLoader = false;
          that.renderer.addClass(document.body, 'modal-open');
          that.overlay = 'block';
          let disposable = await that.dialog.addDialog(ConfirmUploadComponent, {
            title: '',
            message: 'This photo is actually pretty small. It will probably make a blurry tile!'
          })
            .subscribe(async (isConfirmed) => {
              if (isConfirmed) {
                that.mainLoader = true;
                await that.keepImage(files);
              }
              that.overlay = 'none';
            });
        } else {
          await that.keepImage(files);
        }
      };
    }
  }

  keepImage(files) {
    this.progress = true;
    let that = this;
    return this.userService.uploadImage(files[this.currentIndex])
      // console.log(data);
      // .pipe(first())
      .subscribe(event => {
        if (event.type === HttpEventType.UploadProgress) {

          const percentDone = Math.round(100 * event.loaded / event.total);
          this.progressPercentage = `${percentDone}%`;
          if (percentDone == 100) {
            this.progressPercentage = 'Processing';
          }
        } else if (event instanceof HttpResponse) {
          this.progress = false;
          this.progressPercentage = '0%';
          let data = event.body;
          if (data && data.msg_code == 200) {
            this.submitButton = true;
            const tilesData = JSON.parse(localStorage.getItem('tiles'));
            if (typeof tilesData !== 'undefined' && tilesData !== null) {
              var image_url = data.data.url;
              var image_crop_url = data.data.crop_url;
              this.imageUrl = data.data.url;

              tilesData.push({ id: '', image: image_url, cropUrl: image_crop_url, qty: 1 });
              localStorage.setItem('tiles', JSON.stringify(tilesData));
              this.quantity += 1;
              this.quantityCheck = this.checkQuantity();
              this.photoTiles = tilesData;
              this.mainLoader = false;
            } else {
              var image_url = data.data.url;
              var image_crop_url = data.data.crop_url;
              this.imageUrl = data.data.url;

              let tiles: any = [
                {
                  id: '',
                  image: image_url,
                  cropUrl: image_crop_url,
                  qty: 1
                }
              ];
              localStorage.setItem('tiles', JSON.stringify(tiles));
              this.quantity += 1;
              this.quantityCheck = this.checkQuantity();
              this.photoTiles = tiles;
              this.mainLoader = false;
            }
          }

          if (this.lengthFiles > 0) {
            this.currentIndex += 1;
            this.customPromise(this.lengthFiles, this.currentIndex, files)
          }
        }
      });
  }

  /* keepImage(file) {
    return this.userService.uploadImage(file)
    .pipe(first())
    .subscribe(data => {
      if(data && data.msg_code == 200) {
        this.submitButton = true;
        const tilesData = JSON.parse(localStorage.getItem('tiles'));
        if(typeof tilesData !== 'undefined' && tilesData !== null) {
          var image_url = data.data.url;
          var image_crop_url = data.data.crop_url;
          this.imageUrl = data.data.url;

          tilesData.push({id: '', image: image_url, cropUrl: image_crop_url,  qty: 1});  
          localStorage.setItem('tiles', JSON.stringify(tilesData));
          this.quantity += 1;
          this.quantityCheck = this.checkQuantity();
          this.photoTiles = tilesData;
          this.mainLoader = false;
        } else {
          var image_url = data.data.url;
          var image_crop_url = data.data.crop_url;
          this.imageUrl = data.data.url;

          let tiles: any = [
            {
              id: '',
              image: image_url,
              cropUrl: image_crop_url,
              qty: 1
            }
          ];
          localStorage.setItem('tiles', JSON.stringify(tiles));
          this.quantity += 1;
          this.quantityCheck = this.checkQuantity();
          this.photoTiles = tiles;
          this.mainLoader = false;
        }
      }

      if(this.lengthFiles > 0) {
        this.currentIndex+1;
        this.customPromise(this.lengthFiles, this.currentIndex, file)
      }
      
    });
  } */

  checkout() {
    this.loading = true;
    let user = JSON.parse(localStorage.getItem('currentUser'));

    if (user != null) {
      this.userService.getAddress(user.access_token)
        .pipe(first())
        .subscribe(data => {
          if (data.msg_code == 811) {
            this.router.navigate(['/address']);
          } else if (data.msg_code == 603) {
            this.authenticationService.logout();
            this.router.navigate(['/login']);
          } else {
            let tiles = JSON.parse(localStorage.getItem('tiles'));

            tiles.forEach(element => {
              element.tile_color = this.selectedTilesColor;
            });

            let order_id = JSON.parse(localStorage.getItem('order_id'));
            if (order_id == null) {
              order_id = '';
            }
            this.userService.placeorder(tiles, user.access_token, order_id, {})
              .pipe(first())
              .subscribe(data => {
                if (data && data.msg_code == 820) {
                  localStorage.setItem('order_id', data.data.order_id);
                  localStorage.setItem('order', JSON.stringify(data.data));
                  this.router.navigate(['/payment']);
                } else {

                }
                this.loading = false;
                return data;
              });
          }
        });
    } else {
      this.router.navigate(['/login'], { queryParams: { checkout: true } });
    }
  }

  adjust(id, url) {

    this.renderer.addClass(document.body, 'modal-open');
    this.overlay = 'block';
    let disposable = this.dialog.addDialog(AdjustComponent, {
      id: id,
      message: url
    })
      .subscribe(() => {
        this.overlay = 'none';
        this.renderer.removeClass(document.body, 'modal-open');
        const tiles = JSON.parse(localStorage.getItem('tiles'));

        if (typeof tiles !== 'undefined' && tiles !== null) {
          this.overlay = 'none';
          this.photoTiles = tiles;
          this.submitButton = true;
        } else {
          this.overlay = 'none';

          this.submitButton = false;
        }
      });
    // this.http.get(url, {responseType : "blob"}).subscribe((response) => {
    //   console.log(response);
    //   // return JSON.parse(data)

    // });

    //We can close dialog calling disposable.unsubscribe();
    //If dialog was not closed manually close it by timeout
    /* setTimeout(()=>{
        disposable.unsubscribe();
    },10000); */
  }

  reduceTiles(i) {
    const tiles = JSON.parse(localStorage.getItem('tiles'));

    if (tiles[i].qty > 1) {
      tiles[i].qty -= 1;

      this.quantity -= 1;
      this.quantityCheck = this.checkQuantity();
    }

    this.photoTiles = tiles;
    localStorage.setItem('tiles', JSON.stringify(tiles));
  }

  increaseTiles(i) {
    const tiles = JSON.parse(localStorage.getItem('tiles'));

    if (tiles[i].qty < 10) {
      tiles[i].qty += 1;

      this.quantity += 1;
      this.quantityCheck = this.checkQuantity();
    }

    this.photoTiles = tiles;
    localStorage.setItem('tiles', JSON.stringify(tiles));
  }

  checkQuantity(): boolean {
    if (this.quantity < 4) {
      return false;
    } else {
      return true;
    }
  }
}
