import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, NavigationEnd } from '@angular/router';
import { Location } from '@angular/common';
import { UserService, HeaderService, AlertService } from '../_services';
import { first, filter } from 'rxjs/operators';

@Component({
  selector: 'app-guestcheckout',
  templateUrl: './guestcheckout.component.html',
  styleUrls: ['./guestcheckout.component.scss']
})
export class GuestcheckoutComponent implements OnInit {
  title = 'Snap Tiles - Guest Info';
  guestInfoForm: FormGroup;
  loading: boolean = false;
  submitted: boolean = false;
  returnUrl: string = '';
  error: string = '';
  readonly: boolean = true;
  guestInfo: any = {
    access_token: '',
    p_first_name:'',
    p_last_name:'',
    email:'',
    phone_country_code:'+1',
    phone_no:'',
    order_address: '',
    order_address_2: '',
    order_city: '',
    order_state: '',
    order_zipcode: '',
    country_id: 233
  }

  constructor (
    private formBuilder: FormBuilder,
    private router: Router,
    private userService: UserService,
    private alertService: AlertService,
    private headerService: HeaderService,
    private _location: Location
  ) {
    const guestInfo = JSON.parse(localStorage.getItem('guestInfo'));
    if(guestInfo != null) {
      this.guestInfo.p_first_name = guestInfo.p_first_name;
      this.guestInfo.p_last_name = guestInfo.p_last_name;
      this.guestInfo.email = guestInfo.email;
      this.guestInfo.phone_country_code = guestInfo.phone_country_code;
      this.guestInfo.phone_no = guestInfo.phone_no;
      this.guestInfo.order_address = guestInfo.order_address;
      this.guestInfo.order_address_2 = guestInfo.order_address_2;
      this.guestInfo.order_city = guestInfo.order_city;
      this.guestInfo.order_state = guestInfo.order_state;
      this.guestInfo.order_zipcode = guestInfo.order_zipcode;
      this.guestInfo.country_id = guestInfo.country_id;
    }
  }

  ngOnInit() {
    this.headerService.setTitle('Add guestInfo');
    
    this.router.events.subscribe((evt) => {
        if (!(evt instanceof NavigationEnd)) {
            return;
        }
        window.scrollTo(0, 0)
    });

    /*let user = JSON.parse(localStorage.getItem('currentUser'));
    if(user != '' && user != null) {
      this.headerService.setMenu('true', 'AddressPage');
    } else {*/
      this.headerService.setMenu('false', 'AddressPage');
    // }

    this.guestInfoForm = this.formBuilder.group({
      p_first_name: ['', Validators.required],
      p_last_name: ['', Validators.required],
      email: ['', Validators.required],
      phone_country_code: ['+1', Validators.required],
      phone_no: [''],
      order_address: ['', Validators.required],
      order_address_2: [''],
      order_city: ['', Validators.required],
      order_state: ['', Validators.required],
      order_zipcode: ['', Validators.required], 
      country_id: ['233', Validators.required] 
    })
  }

  get f() {
    return this.guestInfoForm.controls;
  }

  number(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if(charCode > 33 && (charCode < 48 || charCode > 57 )) {
      return false;
    }
    return true;
  }

  onSubmit() {
      this.submitted = true;
      
      if(this.guestInfoForm.invalid) {
        return;
      }

      this.loading = true;
      
      /*const user = JSON.parse(localStorage.getItem('currentUser'));
      this.guestInfo.access_token = user.access_token;*/

      this.guestInfo.p_first_name = this.f.p_first_name.value;
      this.guestInfo.p_last_name = this.f.p_last_name.value;
      this.guestInfo.email = this.f.email.value;
      this.guestInfo.phone_country_code = this.f.phone_country_code.value;
      this.guestInfo.phone_no = this.f.phone_no.value;
      this.guestInfo.order_address = this.f.order_address.value;
      this.guestInfo.order_address_2 = this.f.order_address_2.value;
      this.guestInfo.order_city = this.f.order_city.value;
      this.guestInfo.order_state = this.f.order_state.value;
      this.guestInfo.order_zipcode = this.f.order_zipcode.value;
      this.guestInfo.country_id = 223;


  	localStorage.setItem('guestInfo', JSON.stringify(this.guestInfo));
  	
  	const back = localStorage.getItem('back');
  	
  	if(typeof back !== 'undefined' && back !== null) {
  		this.router.navigate(['/profile']);
  	} else {
  		let tiles = JSON.parse(localStorage.getItem('tiles'));
  		let quantity = 0;
  		if(typeof tiles !== 'undefined' && tiles !== null) {
  		  if(tiles.length > 0) {
  		    tiles.forEach(tile => {
  		      tile.tile_color = localStorage.getItem('selectedTile');
  		      quantity += tile.qty;
  		    });

  		    if(quantity >= 4) {
  		       let order_id = JSON.parse(localStorage.getItem('order_id'));
  		       if(order_id == null) {
  		       	order_id = '';
  		       }
  		       this.guestInfo.phone_no = this.guestInfo.phone_country_code+this.guestInfo.phone_no;
  		       tiles.user_detail = this.guestInfo;

  		       console.log('this.guestInfo: ',this.guestInfo);

  		       this.userService.placeorder(tiles, this.guestInfo.access_token, order_id, this.guestInfo)
  		       .pipe(first())
  		       .subscribe(data => {
  			        if(data && data.msg_code == 820) {
  			          localStorage.setItem('order_id', data.data.order_id);
  			          localStorage.setItem('order', JSON.stringify(data.data));
  			          this.router.navigate(['/payment'], { queryParams: { guestCheckout: true } });
  			        } else {
  			          this.router.navigate(['/tiles']);      
  			        }
  		        }, error => {
  			      this.loading = false;
  			      this.alertService.error(error);
  			    });

  		    } else {
  		      	this.router.navigate(['/tiles']);  
  		    }
  		  } else {
  		    	this.router.navigate(['/tiles']);
  		  }
  		} else {
  		  	this.router.navigate(['/tiles']);
  		}
  	}   
  }
  
}
