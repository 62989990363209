import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';

import { AuthenticationService, HeaderService, AlertService } from '../_services';
import { environment } from 'src/environments/environment';

@Component({
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  title = '';
  resetPasswordForm: FormGroup;
  submitted: boolean = false;
  loading: boolean = false;
  otp: string = '';
  reset_token: string = '';
  otpPlaceholder: any = {
    otp1: '0',
    otp2: '0',
    otp3: '0',
    otp4: '0',
    otp5: '0',
  } 

  @ViewChild("otpVerify1", {static: false}) otpVerify1: ElementRef;
  @ViewChild("otpVerify2", {static: false}) otpVerify2: ElementRef;
  @ViewChild("otpVerify3", {static: false}) otpVerify3: ElementRef;
  @ViewChild("otpVerify4", {static: false}) otpVerify4: ElementRef;
  @ViewChild("otpVerify5", {static: false}) otpVerify5: ElementRef;
  constructor (
    private formBuilder: FormBuilder,
    private router: Router,
    private authenticationService: AuthenticationService,
    private headerService: HeaderService,
    private alertService: AlertService
  ) {
    if(this.authenticationService.currentUserValue) {
      this.router.navigate(['/']);
    }
  }

  ngOnInit() {
    this.headerService.setTitle(this.title);
    
    this.reset_token = localStorage.getItem('resetPassword');

    if(this.reset_token != '') {
      this.authenticationService.checkResetToken(this.reset_token)
      .pipe(first())
      .subscribe(data => {
        if(data) {
          if(data.msg_code == environment.resetTokenExist) {
            
          } else {
            this.router.navigate(['/forgot-password']);
          }
        }
      },
      error => {
        this.router.navigate(['/forgot-password']);
      });
    } else {
      this.router.navigate(['/forgot-password']);
    }

    this.resetPasswordForm = this.formBuilder.group({
      otpVerify1: ['', [Validators.required, Validators.maxLength(1)]],
      otpVerify2: ['', [Validators.required, Validators.maxLength(1)]],
      otpVerify3: ['', [Validators.required, Validators.maxLength(1)]],
      otpVerify4: ['', [Validators.required, Validators.maxLength(1)]],
      otpVerify5: ['', [Validators.required, Validators.maxLength(1)]],
      password: ['', Validators.required],
      confirm_password: ['', Validators.required],
    }, {validator: this.checkPasswords});
  }

  get f() {
    return this.resetPasswordForm.controls; 
  }

  checkPasswords(group: FormGroup) {
    return group.controls.password.value === group.controls.confirm_password.value ? null : { notSame: true }     
  }

  addPlaceholder(i) {
    switch(i) {
      case 1: this.otpPlaceholder.otp1 = '0';
        break;
      case 2: this.otpPlaceholder.otp2 = '0';
        break;
      case 3: this.otpPlaceholder.otp3 = '0';
        break;
      case 4: this.otpPlaceholder.otp4 = '0';
        break;
      case 5: this.otpPlaceholder.otp5 = '0';
        break;
    }
  }

  removePlaceholder(i) {
    switch(i) {
      case 1: this.otpPlaceholder.otp1 = '';
        break;
      case 2: this.otpPlaceholder.otp2 = '';
        break;
      case 3: this.otpPlaceholder.otp3 = '';
        break;
      case 4: this.otpPlaceholder.otp4 = '';
        break;
      case 5: this.otpPlaceholder.otp5 = '';
        break;
    }
  }

  number(event, key): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if(charCode > 33 && (charCode < 48 || charCode > 57 )) {
      return false;
    }

    switch (key) {
      case 1: this.otpVerify2.nativeElement.focus();
            break;
      case 2: this.otpVerify3.nativeElement.focus();
            break;
      case 3: this.otpVerify4.nativeElement.focus();
            break;
      case 4: this.otpVerify5.nativeElement.focus();
            break;
    }
    return true;
  }

  onSubmit() {
    this.submitted = true;

    if(this.resetPasswordForm.invalid) {
      return;
    }

    this.loading = true;
    this.otp = this.f.otpVerify1.value+''+this.f.otpVerify2.value+''+this.f.otpVerify3.value+''+this.f.otpVerify4.value+''+this.f.otpVerify5.value;
    this.authenticationService.resetPassword(this.otp, this.f.password.value, this.f.confirm_password.value, this.reset_token)
    .pipe(first())
    .subscribe(data => {
      if(data) {
        if(data.msg_code == 608) {
          this.alertService.success(data.message);
          setTimeout(() => {
            this.router.navigate(['/login']);
          }, 3000);
        } else {
          this.submitted = false;
          this.loading = false;
          this.alertService.error(data.message);
        }
      }
    });
  }
}
