import { BrowserModule } from '@angular/platform-browser';
import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpModule } from '@angular/http';
import { BootstrapModalModule } from 'ng6-bootstrap-modal';
import { NgxPayPalModule } from 'ngx-paypal';
import { SocialLoginModule, FacebookLoginProvider, AuthServiceConfig } from 'angular-6-social-login';
import { DeviceDetectorModule } from 'ngx-device-detector';
// import { CropperModule } from 'ngx-cropper';
import { ImageCropperModule } from 'ngx-image-cropper';
// import { NgxCropperjsModule } from 'ngx-cropperjs';
import { AngularCropperjsModule } from 'angular-cropperjs';
// import {NgxImageEditorModule} from "ngx-image-editor";
import {DOCUMENT} from '@angular/common';

import { NgxBraintreeModule } from 'ngx-braintree';

import { AppComponent } from './app.component';
import { routing } from './app.routing';

import { LandingComponent } from './landing';
import { TilesComponent } from './tiles';
import { LoginComponent } from './login';
import { RegistrationComponent } from './registration';
import { ForgotPasswordComponent } from './forgot-password';
import { ResetPasswordComponent } from './reset-password';
import { ChangePasswordComponent } from './change-password';
import { EmailVerificationComponent } from './email-verification';
import { MobileVerificationComponent } from './mobile-verification';
import { ProfileComponent } from './profile';
import { AddressComponent } from './address';
import { MyOrdersComponent } from './my-orders';
import { OrderStatusComponent } from './order-status';
import { OrderDetailsComponent } from './order-details';
import { PaymentComponent } from './payment';
import { StaticPagesComponent } from './static-pages';

import { AuthenticationService, UserService, HeaderService, AlertService } from './_services';
import { AuthGuard } from './_guards';
import { AlertComponent, UploadComponent, AdjustComponent, ConfirmUploadComponent } from './_directives';
import { environment } from 'src/environments/environment';
// import { JwtInterceptor } from './_helpers';
import { FacebookModule } from 'ngx-facebook';
import { GuestcheckoutComponent } from './guestcheckout/guestcheckout.component';

export function getAuthServiceConfigs() {
  let config = new AuthServiceConfig(
    [
      {
        id: FacebookLoginProvider.PROVIDER_ID,
        provider: new FacebookLoginProvider(environment.facebook_client_key)
      },
    ]
  );
  return config;
}

@NgModule({
  declarations: [
    AppComponent,
    LandingComponent,
    TilesComponent,
    LoginComponent,
    RegistrationComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    ChangePasswordComponent,
    EmailVerificationComponent,
    MobileVerificationComponent,
    ProfileComponent,
    AddressComponent,
    MyOrdersComponent,
    OrderStatusComponent,
    OrderDetailsComponent,
    PaymentComponent,
    StaticPagesComponent,
    AlertComponent,
    UploadComponent,
    AdjustComponent,
    ConfirmUploadComponent,
    GuestcheckoutComponent
  ],
  imports: [
    BrowserModule,
    AngularFontAwesomeModule,
    ReactiveFormsModule,
    HttpClientModule,
    HttpModule,
    NgxBraintreeModule,
    NgxPayPalModule,
    BootstrapModalModule,
    SocialLoginModule,
    // CropperModule,
    ImageCropperModule,
    // NgxCropperjsModule,
    AngularCropperjsModule,
    // NgxImageEditorModule,
    DeviceDetectorModule.forRoot(),
    FacebookModule.forRoot(),
    routing
  ],
  providers: [
    AuthenticationService,
    AuthGuard,
    UserService,
    HeaderService,
    AlertService,
    { provide: AuthServiceConfig, useFactory: getAuthServiceConfigs }
    // { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true }
  ],
  entryComponents: [
    UploadComponent,
    AdjustComponent,
    ConfirmUploadComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
