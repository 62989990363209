import { Component, OnInit, NgZone, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { UserService, AuthenticationService, HeaderService, AlertService } from '../_services';
import { first } from 'rxjs/operators';
import { IPayPalConfig, ICreateOrderRequest } from 'ngx-paypal';
import { environment } from 'src/environments/environment';
// import { ActionSequence } from 'protractor';
// import { Observable, of } from 'rxjs';
// import { NgxBraintreeService } from 'ngx-braintree';
import { NgxBraintreeModule } from 'ngx-braintree';

// declare var braintree: any;

@Component({
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss'],
  styles: [`
   .btnWrapper /deep/ ngx-braintree button {
      padding: 10px 20px;
    }
    .btnWrapper /deep/ ngx-braintree .braintree-heading {
      font-family: "GothamMedium";
      font-size: 15px;
      color: #00a2a8;
      visibility: hidden;
    }
    .btnWrapper /deep/ ngx-braintree .braintree-heading:after {
      visibility: visible;
      padding: 0;
      position: absolute;
      left: 0;
      width: 290px;
      text-align: left;
      content: 'Choose Payment Method';
    }
  `]
})
export class PaymentComponent implements OnInit {
  couponForm: FormGroup;
  clientTokenUrl: string = "";
  purchaseURL: string = "";
  enabledStyle = {
    'background-color': '#eb1e59',
    'color': '#ffffff',
    'border': 'none',
    'border-radius': '4px',
    'height': '40px',
    'line-height': '40px',
    'font-size': '16px',
    'cursor': 'pointer',
    'padding': '0px 20px 0px 20px'
   };
   
  disabledStyle = {
    'background-color': 'lightgray',
    'color': '#ffffff',
    'border': 'none',
    'border-radius': '4px',
    'height': '40px',
    'line-height': '40px',
    'font-size': '16px',
    'cursor': 'not-allowed',
    'padding': '0px 20px 0px 20px'
  };

  public payPalConfig?: IPayPalConfig;
  title = 'Payment';
  submitted: boolean = false;
  loading: boolean = false;
  error: string = '';
  addressData: boolean = false;
  address: string = '';
  addressRecord: any = {
    address_1: '',
    address_2: '',
    city: '',
    state: '',
    postcode: ''
  };
  totalAmount: string = '1';
  couponCode : string = '';
  couponStatus = false;
  showSuccess: boolean = false;
  orderTiles: any;
  total_pic: string;
  client_token: string;
  order_id: string;

  mainLoader: boolean = false;
  charge: any = {
    delivery: 0,
    discount: 0,
    sub_total: 0,
    total: 0,
  };
  note: string = '';

  user: any = {
    first_name: '',
    last_name: '',
    email: '',
    phone_no: '',
  };
  guestCheckout: boolean = false;
  orderStatusApiCallCount: number = 0;
  orderStatusInterval: any = 0;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private authenticationService: AuthenticationService,
    private alertService: AlertService,
    private headerService: HeaderService,
    private ngZone: NgZone
  ) {

  }

  ngOnInit() {
    
    this.route.queryParams
    .subscribe(params => {
      // console.log('params::::::::::::::::::::::::::::::;',params);
      this.guestCheckout = typeof params.guestCheckout != 'undefined' ? params.guestCheckout : false; 
    });

    this.mainLoader = true;

    this.headerService.setTitle('Order Details');

    if (this.guestCheckout) {

      const guestInfo = JSON.parse(localStorage.getItem('guestInfo'));

      if (guestInfo != '' && guestInfo != null) {
        this.headerService.setMenu('true', 'PaymentPage');
      } else {
        this.headerService.setMenu('false', 'PaymentPage');
      }

      this.user.first_name = guestInfo.p_first_name;
      this.user.last_name = guestInfo.p_last_name;
      this.user.access_token = guestInfo.access_token;
      this.user.phone_no = guestInfo.phone_no;
      this.user.email = guestInfo.email;

      this.addressData = true;
      this.addressRecord.address_1 = guestInfo.order_address;
      this.addressRecord.address_2 = guestInfo.order_address_2;
      this.addressRecord.city = guestInfo.order_city;
      this.addressRecord.state = guestInfo.order_state;
      this.addressRecord.postcode = guestInfo.order_zipcode;

      this.address = guestInfo.p_first_name+ ' '+ guestInfo.p_last_name + '<br>' + guestInfo.email + '<br>' + guestInfo.order_address + ' ' + guestInfo.order_address_2 + ', ' + guestInfo.order_city + ', ' + guestInfo.order_state + ', ' + guestInfo.order_zipcode;
      if (guestInfo.country_id == 233) {
        this.address += ', USA';
      }

      if (guestInfo.phone_no) {
        this.address += '<br>Phone No. '+guestInfo.phone_no;
      }

    }else{
      
      const user = JSON.parse(localStorage.getItem('currentUser'));

      if (user != '' && user != null) {
        this.headerService.setMenu('true', 'PaymentPage');
      } else {
        this.mainLoader = false;
        this.addressData = false;
        this.authenticationService.logout();
        this.router.navigate(['/login']);

        this.headerService.setMenu('false', 'PaymentPage');
      }

      this.user.first_name = user.u_first_name;
      this.user.last_name = user.u_last_name;
      this.user.access_token = user.access_token;
      this.user.phone_no = user.phone_no;
      this.user.email = user.email;

      this.userService.getAddress(this.user.access_token)
        .pipe(first())
        .subscribe(data => {
          if (data && data.msg_code == 811) {
            this.addressData = false;
          } else if (data && data.msg_code == 603) {
            console.log(data);
            this.mainLoader = false;
            this.addressData = false;
            this.authenticationService.logout();
            this.router.navigate(['/login']);
          } else {
            const address = data.data;

            this.addressData = true;
            this.addressRecord.address_1 = address.address_1;
            this.addressRecord.address_2 = address.address_2;
            this.addressRecord.city = address.city;
            this.addressRecord.state = address.state;
            this.addressRecord.postcode = address.postcode;

            this.address = address.address_1 + ' ' + address.address_2 + ', ' + address.city + ', ' + address.state + ', ' + address.postcode;
            if (address.country_id == 233) {
              this.address += ', USA';
            }
          }
        });
    }

    
    const order = JSON.parse(localStorage.getItem('order'));

      if (order != null) {
          
        console.log('order :::::::::::::: ', order);

        let appliedCoupon = localStorage.getItem('appliedCoupon');
        
        if (appliedCoupon) {
          let appliedCouponData = JSON.parse(appliedCoupon);
          console.log('appliedCoupon >>>>>>>>>>>>>>> ', appliedCouponData);
          if (appliedCouponData && appliedCouponData.order_id == order.order_id) {
            this.couponCode = appliedCouponData.coupon_code;
            this.checkCouponStatus();
          }
        }

        this.order_id = order.order_id;
        this.client_token = order.payment_client_token;
        this.orderTiles = order.data;
        this.charge.discount = order.discount;
        this.charge.delivery = order.delivery_charge;
        this.charge.sub_total = order.sub_total;
        this.charge.total = order.total_amt;
        this.total_pic = order.total_pic;
        this.note = order.note;

        this.makePayment(this.charge.total, this.addressRecord, this.user);

        this.mainLoader = false;
        this.clientTokenUrl = environment.apiUrl+"/create-client-token/?order_id="+this.order_id;
        this.purchaseURL = environment.apiUrl+"/payment-now/?order_id="+this.order_id;
      } else {
        this.router.navigate(['/tiles']);
      }

      this.couponForm = this.formBuilder.group({
        coupon_code: ['', Validators.required]
      });
  }

  get f() {
    return this.couponForm.controls;
  }

  applyCoupon() {
    this.submitted = true;
    
    if(this.couponForm.invalid) {
      this.alertService.error("Please enter a valid coupon code.");
      return;
    }

    this.loading = true;
    this.mainLoader = true;
    this.couponForm.value.coupon_code;

    let order_id = JSON.parse(localStorage.getItem('order_id'));
     
    console.log('order_id', order_id, 'this.couponForm.value.coupon_code', this.couponForm.value.coupon_code);
    
    this.userService.applyCoupon(order_id, this.couponForm.value.coupon_code)
     .pipe(first())
     .subscribe(data => {
        this.loading = false;
        this.mainLoader = false;
        if(data && data.status_code == 200) {
          let couponCodeData = {"order_id":order_id, "coupon_code":this.couponForm.value.coupon_code};
          localStorage.setItem('appliedCoupon', JSON.stringify(couponCodeData));
          this.couponCode = this.couponForm.value.coupon_code;
          this.alertService.success(data.message);      
          let orderData = JSON.parse(localStorage.getItem('order'));
          orderData.discount = data.data.discount;
          orderData.discount_msg = data.data.discount_msg;
          orderData.sub_total = data.data.sub_total;
          orderData.total_amt = data.data.total_amt;
          localStorage.setItem('order', JSON.stringify(orderData));

          // Assign values to make payment
          this.charge.discount = data.data.discount;
          this.charge.sub_total = data.data.sub_total;
          this.charge.total = data.data.total_amt;

          this.makePayment(this.charge.total, this.addressRecord, this.user);
          this.checkCouponStatus();
          // this.router.navigate(['/payment'], { queryParams: { guestCheckout: true } });
        } else {
          this.couponForm.reset();
          this.loading = false;
          this.alertService.error(data.message);      
        }
      }, error => {
          this.couponForm.reset();
          this.loading = false;
          this.alertService.error(error);
      });

  } 

  removeCoupon(){
    this.loading = true;
    this.mainLoader = true;
    let order_id = JSON.parse(localStorage.getItem('order_id'));
    
    this.userService.removeCoupon(order_id)
     .pipe(first())
     .subscribe(data => {
        this.loading = false;
        this.mainLoader = false;
        if(data && data.status_code == 200) {
          localStorage.removeItem('appliedCoupon');
          this.couponCode = '';
          this.couponForm.reset();
          this.alertService.success(data.message);      
          let orderData = JSON.parse(localStorage.getItem('order'));
          orderData.discount = data.data.discount;
          orderData.discount_msg = data.data.discount_msg;
          orderData.sub_total = data.data.sub_total;
          orderData.total_amt = data.data.total_amt;
          localStorage.setItem('order', JSON.stringify(orderData));

          // Assign values to make payment
          this.charge.discount = data.data.discount;
          this.charge.sub_total = data.data.sub_total;
          this.charge.total = data.data.total_amt;

          this.makePayment(this.charge.total, this.addressRecord, this.user);

          // this.router.navigate(['/payment'], { queryParams: { guestCheckout: true } });
        } else {
          this.couponForm.reset();
          this.loading = false;
          this.alertService.error(data.message);      
        }
      }, error => {
      this.loading = false;
      this.alertService.error(error);
    });
  }

  removeCouponOnExpired(data){    
    localStorage.removeItem('appliedCoupon');
    this.couponCode = '';
    this.couponForm.reset();
    this.alertService.error("Offer expired on this order.");      
    let orderData = JSON.parse(localStorage.getItem('order'));
    orderData.discount = data.data.discount;
    orderData.discount_msg = data.data.discount_msg;
    orderData.sub_total = data.data.sub_total;
    orderData.total_amt = data.data.total_amt;
    localStorage.setItem('order', JSON.stringify(orderData));
    // Assign values to make payment
    this.charge.discount = data.data.discount;
    this.charge.sub_total = data.data.sub_total;
    this.charge.total = data.data.total_amt;
    this.makePayment(this.charge.total, this.addressRecord, this.user);
  }

  openAddress() {
    if (this.guestCheckout) {
      this.router.navigate(['/guest-checkout']);
    }else{
      this.router.navigate(['/address']);
    }
  }

  makePayment(payment, address, user) {

    console.log('payment>> ',payment, 'address>> ',address, 'user>>> ', user);

    var that = this;

    this.payPalConfig = {
      currency: 'USD',
      clientId: environment.paypal_client_id,
      createOrderOnClient: (data) => <ICreateOrderRequest>{
        intent: 'CAPTURE',
        purchase_units: [
          {
            amount: {
              currency_code: 'USD',
              value: payment,
              breakdown: {
                item_total: {
                  currency_code: 'USD',
                  value: payment
                }
              }
            },
            items: [
              {
                name: that.order_id + '/SnapTiles',
                quantity: '1',
                category: 'DIGITAL_GOODS',
                unit_amount: {
                  currency_code: 'USD',
                  value: payment,
                }
              }
            ],
            shipping: {
              name: {
                full_name: user.first_name + ' ' +user.last_name,
              },
              address: {
                country_code: 'IN',
                address_line_1: address.address_1+' '+address.address_2,
                admin_area_2: address.city,
                admin_area_1: address.state,
                postal_code: address.postcode,
              }
            }
          },
        ]
      },
      advanced: {
        commit: 'true'
      },
      style: {
        label: 'pay',
        layout: 'vertical',
        color: 'blue'
      },
      onApprove: (data, actions) => {
        this.mainLoader = true;
        console.log('On Approve - Transaction was approve, but not authorized.', data, actions)
        actions.order.get().then(details => {
          console.log('On Approve - You can get full order details inside.', details);
        });
      },
      onClientAuthorization: (data) => {
        console.log('On Client Autherization', data);
        this.getOrderStatus(that.order_id);
      },
      onCancel: (data, actions) => {
        this.userService.orderStatus(that.order_id)
          .pipe(first())
          .subscribe(data => {
            localStorage.removeItem('order_id');
            localStorage.removeItem('tiles');
            localStorage.removeItem('order');
            this.mainLoader = false;
            console.log('order status cancel data ', data);
          }, error => {
            this.mainLoader = false;
          });
          this.showSuccess = false;
        console.log('On Cancel', data, actions);
      },
      onError: err => {
        console.log('On Error', err);
      },
      onClick: (data, actions) => {
        console.log('On Click', data, actions);
      }
    };
  }

  makeFreePayment(){

    this.loading = true;
    this.mainLoader = true;
    
    let order_id = JSON.parse(localStorage.getItem('order_id'));
    
    this.userService.makeFreePayment(order_id)
     .pipe(first())
     .subscribe(data => {
        console.log(data, ' :: :: <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<');
        this.loading = false;
        this.mainLoader = false;
        if(data && data.status_code == 200) {
          localStorage.removeItem('order_id');
          localStorage.removeItem('tiles');
          localStorage.removeItem('order');
          localStorage.removeItem('appliedCoupon');
          
          localStorage.setItem('showSuccess', 'true');
          if (this.guestCheckout) {
            this.ngZone.run(() => this.router.navigate(['/order-status'], { queryParams: { guestCheckout: true } }));
          }else{
            this.ngZone.run(() => this.router.navigate(['/order-status']));
          }

          this.alertService.success(data.message);
        } else {
          this.alertService.error(data.message);     
        }
      }, error => {
          this.loading = false;
          this.mainLoader = false;
          this.alertService.error(error);
      });
  }

  checkCouponStatus(){
    let order_id = JSON.parse(localStorage.getItem('order_id'));
    this.userService.checkCouponStatus(order_id)
     .pipe(first())
     .subscribe(data => {
        console.log('coupon status:: ',data);
        if(data && data.status_code == 200 && data.msg_code == 834) {          
          var that = this;
          setTimeout(function() {
            that.checkCouponStatus();
          }, 3000);
          this.couponStatus = true;
        } else if (data && data.status_code == 201 && data.msg_code == 835) {
          this.removeCouponOnExpired(data);
          this.couponStatus = false;
        } else if (data && data.status_code == 200 && data.msg_code == 836) {
          localStorage.removeItem('appliedCoupon');
          this.couponCode = '';
          this.couponForm.reset();
          this.couponStatus = false;
        }
      }, error => {
          this.couponStatus = false;
      });
  }
  // Get order status and redirect on succes page
  getOrderStatus(order_id){
    this.userService.orderStatus(order_id)
          .pipe(first())
          .subscribe(orderStatusData => {
            console.log('Order Status Api Data', orderStatusData);
            let orderstatus = orderStatusData.data.order_status;
            localStorage.removeItem('order_id');
            localStorage.removeItem('tiles');
            localStorage.removeItem('order');
            if (orderstatus == 1) {
              // Show order status page
              
              this.mainLoader = false;
              localStorage.setItem('showSuccess', 'true');
              if (this.guestCheckout) {
                this.ngZone.run(() => this.router.navigate(['/order-status'], { queryParams: { guestCheckout: true } }));
              }else{
                this.ngZone.run(() => this.router.navigate(['/order-status']));
              }
              console.log("api response on success", orderStatusData.data.order_status_text);
            }else if (orderstatus == 2 || orderstatus == 0 ) {
              this.orderStatusApiCallCount++;
              if (this.orderStatusApiCallCount<10) {
                var that = this;
                setTimeout(function() {
                  that.getOrderStatus(order_id);
                }, 9000);
              }
              console.log("api response on success", orderStatusData.data.order_status_text);
            }

          }, error => {
            this.mainLoader = false;
          });


        // this.userService.paymentProcess(data, this.order_id, this.user.access_token)
        //   .pipe(first())
        //   .subscribe(data => {
        //     console.log(data);
        //     if(data && data.msg_code == 823) {
        //       localStorage.removeItem('order_id');
        //       localStorage.removeItem('tiles');
        //       localStorage.removeItem('order');
        //       this.mainLoader = false;
        //       localStorage.setItem('showSuccess', 'true');
        //       this.ngZone.run(() => this.router.navigate(['/my-orders']));
        //     }
        //   }, error => {
        //     this.mainLoader = false;
        //   });
  }



  onPaymentStatus(res){
    if (res && res.status_code == 200) {
      this.getOrderStatus(this.order_id);
    }else{
      this.alertService.error(res.message);   
       this.checkCouponStatus();
       var that = this;
       setTimeout(function() {
        location.reload();
       }, 4000);
    }
  }


}
