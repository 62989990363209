// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// Testing Server
// apiUrl: 'http://192.168.100.118/snaptiles/api',
// apiUrl: 'http://dynamicwebsite.co.in/ss/snaptiles/qa/api',
// apiUrl: 'http://192.168.100.123/snaptiles-dev/api',
// instagram_client_id: '3371ced9049e4d51b9006379b0df6c28',
// instagram_redirect_url: 'https://dynamicwebsite.co.in/ss/snaptile/instagram',
// instagram_redirect_url: 'http://localhost:4200/instagram',
// facebook_client_key: '667773853654915',
// paypal_client_id: 'AXW0wgCuEBy9dSpHhEwzarnDikjsk9Jr_3ocnSfgClJ4iva0K-DEQZ2dgNgsmB83z_LfwkeVREKOSd-e'

// Live Server
// instagram_client_id: '54b78acb1d414070b6e683d66b91251e',
// facebook_client_key: '3313213262025995',
// paypal_client_id: 'Af45rd6-sMCJzeRftRiW4ERo1dnejGXdFLVd7hh91bTIKuG74QgcCwU2WUAtAWxbdFiq2jqi5p0aplxl',
// instagram_redirect_url: 'https://dynamicwebsite.co.in/ss/snaptile/instagram',
// apiUrl: 'https://administrator.snaptiles.com/api/'
// instagram_redirect_url: 'https://snaptiles.com/instagram',

//Live Server
export const environment = {
  production: true,
  apiUrl: 'https://snaptiles.com/admin/api',
  staticPages: 'https://snaptiles.com/admin',
  instagram_client_id: '54b78acb1d414070b6e683d66b91251e',
  instagram_redirect_url: 'https://snaptiles.com/instagram',
  facebook_client_key: '3313213262025995',
  paypal_client_id: 'Af45rd6-sMCJzeRftRiW4ERo1dnejGXdFLVd7hh91bTIKuG74QgcCwU2WUAtAWxbdFiq2jqi5p0aplxl',
  forgotPasswordEmailCheck: 606,
  resetToken: 622,
  resetTokenExist: 302,
  successResponse: 301,
  failedResponse: 201,
  forgotPasswordSuccess: 622,
  emailPhoneCheck: 610,
  emailPhoneNotVerified: 801,
  emailNotVerified: 802,
  mobileNotVerified: 803
}; 


// Dyanamic Config
/*export const environment = {
  production: true,
  apiUrl: 'https://dynamicwebsite.co.in/ss/snaptiles/qa/api',
  staticPages: 'https://dynamicwebsite.co.in/ss/snaptiles/qa',
  instagram_client_id: '54b78acb1d414070b6e683d66b91251e',
  instagram_redirect_url: 'https://dynamicwebiste.co.in/ss/snaptile/instagram',
  facebook_client_key: '3313213262025995',
  //paypal_client_id: 'Af45rd6-sMCJzeRftRiW4ERo1dnejGXdFLVd7hh91bTIKuG74QgcCwU2WUAtAWxbdFiq2jqi5p0aplxl',
  paypal_client_id: 'AQlPFNAROnCKTW8QQtq-JASB4z7w5X1iwqV7mw13hKCrPtXxwvhs-NVhbY_ivKH56Yr0fibu5MdFwy_a',
  forgotPasswordEmailCheck: 606,
  resetToken: 622,
  resetTokenExist: 302,
  successResponse: 301,
  failedResponse: 201,
  forgotPasswordSuccess: 622,
  emailPhoneCheck: 610,
  emailPhoneNotVerified: 801,
  emailNotVerified: 802,
  mobileNotVerified: 803
};*/

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.