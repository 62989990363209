import { Component, OnInit } from '@angular/core';
import { HeaderService } from '../_services';

@Component({
  templateUrl: './mobile-verification.component.html',
  styleUrls: ['./mobile-verification.component.scss']
})
export class MobileVerificationComponent implements OnInit {
  title = '';

  constructor (
    private headerService: HeaderService
  ) {

  }

  ngOnInit() {
      this.headerService.setTitle(this.title);
  }
}
