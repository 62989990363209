import { Component, OnInit } from '@angular/core';
import { UserService, HeaderService } from '../_services';
import { first } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  templateUrl: './my-orders.component.html',
  styleUrls: ['./my-orders.component.scss']
})
export class MyOrdersComponent implements OnInit {
  title = 'Snap Tiles - My Orders';
  orderList: any = [];
  messageText: string = '';
  mainLoader: boolean = false;

  constructor (
    private userService: UserService,
    private route: ActivatedRoute,
    private router: Router,
    private headerService: HeaderService,
  ) {
    
  }

  ngOnInit() {
    this.headerService.setMenu('false', 'MyOrderPage');
    this.orderLists();
  }

  orderLists() {
    this.mainLoader = true;    
    let user = JSON.parse(localStorage.getItem('currentUser'));
    this.userService.orderList(user.access_token)
    .pipe(first())
    .subscribe(data => {
      if(data && data.msg_code == 200) {
        data.data.forEach(orders => {
          this.orderList.push(orders);
        });
        this.mainLoader = false;
      } else if(data.msg_code == 501) {
        this.messageText = 'Not found any orders.';
        this.mainLoader = false;
      } else {
        this.mainLoader = false;
      }
    }, error => {
      this.mainLoader = false;
    });
  }

  order(id) {
    this.router.navigate(['/order-details/'+id]);
  }
}
