import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';

import { UserService, AuthenticationService, HeaderService, AlertService } from '../_services';
import { environment } from 'src/environments/environment';

@Component({
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss']
})
export class RegistrationComponent implements OnInit {
  title = '';
  registrationForm: FormGroup;
  loading: boolean = false;
  submitted: boolean = false;

  constructor (
    private formBuilder: FormBuilder,
    private router: Router,
    private authenticationService: AuthenticationService,
    public userService: UserService,
    private headerService: HeaderService,
    private alertService: AlertService
  ) {
    if(this.authenticationService.currentUserValue) {
      this.router.navigate(['/']);
    }
  }

  ngOnInit() {
    this.headerService.setTitle(this.title);
    this.headerService.setMenu('false', 'RegistrationPage');
    this.registrationForm = this.formBuilder.group({
      first_name: ['', Validators.required],
      last_name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      phone: ['', [Validators.minLength(6)]],
      password: ['', [Validators.required, Validators.minLength(6)]],
      confirm_password: ['', [Validators.required, Validators.minLength(6)]],
    }, {validator: this.checkPasswords});
  }

  get f() {
    return this.registrationForm.controls;
  }

  checkPasswords(group: FormGroup) {
    return group.controls.password.value === group.controls.confirm_password.value ? null : { notSame: true }     
  }

  number(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if(charCode > 33 && (charCode < 48 || charCode > 57 )) {
      return false;
    }
    return true;
  }

  onSubmit() {
    this.submitted = true;
    
    if(this.registrationForm.invalid) {
      return;
    }

    this.loading = true;
    this.authenticationService.register(this.registrationForm.value)
    .pipe(first())
    .subscribe(
      data => {
        if(data.msg_code == environment.emailPhoneCheck) {
          this.router.navigate(['/address']);
        } else {
          this.alertService.error(data.message);
          this.loading = false;
        }
      },
      error => {
        this.alertService.error(error);
        this.loading = false;
      }
    );
  }
}
