import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';

import { AuthenticationService, HeaderService, AlertService, UserService } from '../_services';
import { environment } from 'src/environments/environment';


 @Component({
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  title = '';
  loginForm: FormGroup;
  submitted: boolean = false;
  loading: boolean = false;
  returnUrl: string;
  error: string = '';
  checkout: boolean = false;

  constructor (
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private headerService: HeaderService,
    private userService: UserService,
    private alertService: AlertService
  ) {
    if(this.authenticationService.currentUserValue) {
      this.router.navigate(['/']);
    }
  }

  ngOnInit() {
    this.headerService.setTitle(this.title);
    this.headerService.setMenu('false', 'LoginPage');

    this.route.queryParams
      .subscribe(params => {
        console.log(params);
        this.checkout = typeof params.checkout != 'undefined' ? params.checkout : false; 
      });

    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
          return;
      }
      window.scrollTo(0, 0)
    });

    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required]
    });

    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }

  get f() {
    return this.loginForm.controls;
  }

  onSubmit() {
    this.submitted = true;

    if(this.loginForm.invalid) {
      return;
    }
    
    this.loading = true;
    this.authenticationService.login(this.f.email.value, this.f.password.value)
    .pipe(first())
    .subscribe(
      data => {
        if(data.msg_code == environment.successResponse) {
          let tiles = JSON.parse(localStorage.getItem('tiles'));
          this.userService.getAddress(data.data.access_token)
          .pipe(first())
          .subscribe(data => {
            if(data.msg_code == 811) {
              this.router.navigate(['/address']);
            } else if(data.msg_code == 603) {
              this.authenticationService.logout();
              this.router.navigate(['/login']);
            } else {
              if(this.checkout) {
                let user = JSON.parse(localStorage.getItem('currentUser'));
                let selectedTileColor = localStorage.getItem('selectedTile');

                tiles.forEach(element => {
                  element.tile_color = selectedTileColor;
                });

                let order_id = JSON.parse(localStorage.getItem('order_id'));
                if(order_id == null) {
                  order_id = '';
                }

                this.userService.placeorder(tiles, user.access_token, order_id, {})
                .pipe(first())
                .subscribe(data => {
                  if(data && data.msg_code == 820) {
                    localStorage.setItem('order_id', data.data.order_id);
                    localStorage.setItem('order', JSON.stringify(data.data));
                    this.router.navigate(['/payment']);    
                  } else {

                  }
                  this.loading = false;
                  return data;
                });
              } else {
                this.router.navigate(['/tiles']);
              }
            }
          });
          
        } else {
          this.submitted = false;
          this.loading = false;  
          this.error = data.message;
          this.alertService.error(data.message);
        }   
      },
      error => {
        this.error = error;
        this.loading = false;
      }
    )
  }
}
