import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { HeaderService, UserService } from '../_services';

@Component({
  templateUrl: './order-status.component.html',
  styleUrls: ['./order-status.component.scss']
})
export class OrderStatusComponent implements OnInit {
  title = 'Snap Tiles - Ordre Status';
  orderList: any = [];
  messageText: string = '';
  mainLoader: boolean = false;
  guestCheckout: boolean = false;

  constructor (
    private userService: UserService,
    private route: ActivatedRoute,
    private router: Router,
    private headerService: HeaderService,
  ) {
    
  }

  ngOnInit() {
    this.route.queryParams
    .subscribe(params => {
      this.guestCheckout = typeof params.guestCheckout != 'undefined' ? params.guestCheckout : false; 
    });

    this.headerService.setMenu('false', 'OrderStatusPage');
    // this.orderLists();
  }

  /*orderLists() {
      this.mainLoader = true;
      let user = JSON.parse(localStorage.getItem('currentUser'));
      this.userService.orderList(user.access_token)
      .pipe(first())
      .subscribe(data => {
        if(data && data.msg_code == 200) {
          data.data.forEach(orders => {
            this.orderList.push(orders);
          });
          this.mainLoader = false;
        } else if(data.msg_code == 501) {
          this.messageText = 'Not found any orders.';
          this.mainLoader = false;
        } else {
          this.mainLoader = false;
        }
      }, error => {
        this.mainLoader = false;
      });
  }

  order(id) {
    this.router.navigate(['/order-details/'+id]);
  }*/
}
