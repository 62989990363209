import { Component, OnInit } from '@angular/core';
import { Router, Route } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserService, HeaderService } from '../_services';

import { first } from 'rxjs/operators';

@Component({
  templateUrl: './email-verification.component.html',
  styleUrls: ['./email-verification.component.scss']
})
export class EmailVerificationComponent implements OnInit {
  title = '';
  emailVerificationForm: FormGroup;
  loading: boolean = false;
  submitted: boolean = false;

  constructor (
    private formBuilder: FormBuilder,
    private router: Router,
    private userService: UserService,
    private headerService: HeaderService
  ) {

  }

  ngOnInit() {
    this.headerService.setTitle(this.title);

    this.emailVerificationForm = this.formBuilder.group({
      otpVerify1: ['', [Validators.required, Validators.maxLength(1)]],
      otpVerify2: ['', [Validators.required, Validators.maxLength(1)]],
      otpVerify3: ['', [Validators.required, Validators.maxLength(1)]],
      otpVerify4: ['', [Validators.required, Validators.maxLength(1)]],
      otpVerify5: ['', [Validators.required, Validators.maxLength(1)]]
    });
  }

  get f() {
    return this.emailVerificationForm.controls;
  }

  onSubmit() {
    this.submitted = true;

    if(this.emailVerificationForm.invalid) {
      return;
    }

    this.loading = true;
  }

  resendCode() {

    this.userService.resendVerificationCode(this.emailVerificationForm.value)
    .pipe(first())
    .subscribe(
      data => {
        /* if(data.msg_code == environment.emailPhoneCheck) {
          this.router.navigate(['/email-verification']);
        } else {
          
        } */
      },
      error => {

      }
    );
  }
}
